import styled from '@emotion/styled'
import { isEmpty } from 'lodash'
import React from 'react'
import { Modifier, ModifierGroup, Option } from 'shop/types/cart'
import { formatMoney } from './utils'

interface Props {
  options: Option[]
  modifierGroups: ModifierGroup[]
}

const Options = ({ options, modifierGroups }: Props) => {
  return (
    <Container>
      {!!options.length &&
        options.map(({ name, values }, index: number) => {
          return (
            <React.Fragment key={`${name}-${index}`}>
              <Name>{name}</Name>
              {values.map((value) => (
                <p>{value}</p>
              ))}
            </React.Fragment>
          )
        })}
      {!isEmpty(modifierGroups) &&
        modifierGroups.map(
          (modifierGroup: ModifierGroup, modifierGroupIndex) => {
            return (
              <span key={`${modifierGroup.name}-${modifierGroupIndex}`}>
                <Name>{modifierGroup.name}</Name>
                {modifierGroup.modifiers.map(
                  (modifier: Modifier, modifierIndex: number) => {
                    const { name, quantity, price } = modifier
                    const amount = parseFloat(price.base)
                    return (
                      <p key={`${name}-${modifierIndex}`}>
                        {quantity > 1 && <span> {quantity} </span>}
                        {name}
                        {amount > 0 && <span> (+{formatMoney(amount)})</span>}
                        {modifierIndex !== modifierGroup.modifiers.length - 1 &&
                          ', '}
                      </p>
                    )
                  }
                )}
              </span>
            )
          }
        )}
    </Container>
  )
}

const Name = styled.p(({ theme }: any) => ({
  marginBottom: '4px',
  color: theme.colors.black
}))

const Container = styled.div(({ theme }: any) => ({
  fontSize: theme.fontSizes[0],
  color: theme.colors.textMute,
  margin: '2px 15% 4px 0',
  lineHeight: '1.3em',
  '> p': {
    margin: '0 0 2px 0',
    '&:last-child': {
      margin: '0 0 0px 0'
    }
  },
  span: {
    '> p': {
      margin: '0 0 2px 0',
      ':first-of-type': {
        margin: '4px 0 2px 0'
      }
    }
  },
  [theme['mediaQueries']['viewport7']]: {
    fontSize: theme.fontSizes[1]
  }
}))

export default Options
