import { CustomerDetailsParams, DomesticAddress, Price } from 'shop/types/cart'

export type FormFields = {
  customerDetails: {
    firstName: string
    lastName: string
    email: string
    contactNumber: string
    contactNumPrefix: string
  }
  recipientDetails: {
    firstName: string
    lastName: string
    contactNumber: string
    contactNumPrefix: string
  }
  forSomeoneElse: boolean
  orderNotes: string
  orderNotesEnabled: boolean
  deliveryAddress: DomesticAddress
  dropoffNotes: string
  marketingOptIn: boolean
  customFieldValue: string
}

export interface CheckoutForm {
  customerDetails: CustomerDetailsCheckout
  recipientDetails: Omit<CustomerDetailsCheckout, 'email'>
  forSomeoneElse: boolean
  orderNotesEnabled: boolean
  orderNotes: string
  deliveryAddress: DomesticAddress
  dropoffNotes: string
  customFieldValue: string
  marketingOptIn: boolean
}

interface CustomerDetailsCheckout extends CustomerDetailsParams {
  contactNumPrefix: string
}

export const ADDRESS_DEFAULTS: DomesticAddress = {
  lineOne: '',
  lineTwo: '',
  city: '',
  zip: ''
}

export const FORM_DEFAULTS: CheckoutForm = {
  customerDetails: {
    firstName: '',
    lastName: '',
    email: '',
    contactNumber: '',
    contactNumPrefix: ''
  },
  recipientDetails: {
    firstName: '',
    lastName: '',
    contactNumber: '',
    contactNumPrefix: ''
  },
  saveAddress: true,
  forSomeoneElse: false,
  orderNotesEnabled: false,
  orderNotes: '',
  deliveryAddress: ADDRESS_DEFAULTS,
  dropoffNotes: '',
  customFieldValue: '',
  marketingOptIn: false
}

export type SplitBillPayor = {
  name: string
  contactNum: string
  amount: number
  email?: string
}

export type DeliveryAddress = {
  line_1: string
  line_2: string
  city: string
  zip: string
}

export type Discount = {
  discount_code: string
  discount_id: string
  discount_amount: number
  discount_target:
    | 'all_charges'
    | 'all_products'
    | 'delivery_fee'
    | 'product_category'
    | 'subtotal_delivery_fee'
    | null
  discount_trigger?: 'manual' | 'automatic' | null
  discount_value: number
  discount_type: 'percentage' | 'fixed' | null
  subtotal_discount_amount: number
  store_fee_info: Price | null
}

// such is life 🤷
export type TimeSlot = {
  datetime: string
  range: string
  value: string
}

export interface CartValidation extends TimeSlotValidation, DiscountValidation {
  unavailableVariantIds: string[]
  valid: boolean
}

export type DiscountValidation = {
  discountWarningsNew: {
    validationType: string
    minimumValue?: string
  }[]
}

export type TimeSlotValidation = {
  isStoreOpen: boolean
  isPreorder: boolean
  nextAvailable?: TimeSlot
}

export type DeliveryReduction = {
  deliveryCharge: number
  deliveryChargeBeforeDiscount: number
  deliveryChargeReduction: number
  deliveryChargeReductionReason: string
  deliveryPricingBand: {
    lowerThreshold: string
    percentageDiscount: number
    upperThreshold: string
  } | null
}
