import { Shop } from 'shop/pages'
import {
  AppContentLoader,
  CartLoader,
  LoaderSwitcher,
  MerchantStoreLoader,
  SlerpMerchantLoader,
  UpdateSlerpCartLoader
} from 'router/loaders'

export const ShopWrapper = (props: { merchant: string; slug: string }) => {
  return (
    <AppContentLoader>
      {(isParentLoaded) => (
        <SlerpMerchantLoader isParentLoaded={isParentLoaded}>
          {(isParentLoaded) => (
            <MerchantStoreLoader
              storeSlug={props.slug}
              isParentLoaded={isParentLoaded}
            >
              {(isParentLoaded) => (
                <CartLoader isParentLoaded={isParentLoaded}>
                  {(isParentLoaded) => (
                    <UpdateSlerpCartLoader isParentLoaded={isParentLoaded}>
                      {(isParentLoaded) => (
                        <LoaderSwitcher isParentLoaded={isParentLoaded}>
                          <Shop {...props} />
                        </LoaderSwitcher>
                      )}
                    </UpdateSlerpCartLoader>
                  )}
                </CartLoader>
              )}
            </MerchantStoreLoader>
          )}
        </SlerpMerchantLoader>
      )}
    </AppContentLoader>
  )
}
