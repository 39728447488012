import React, { useEffect } from 'react'
import styled from '@emotion/styled'
import LoginIcon from './LoginIcon'
import { useCart, useConsumerCart, useModal, useShop } from 'shop/hooks'
import ViewCartButton from './ViewCartButton'
import { getCurrentProductErrors } from 'shop/components/Cart/utils'
import { CheckoutButtonV2, CartTotalV2 } from '.'

interface Props {
  merchantId: string
}

const CartActions = ({ merchantId }: Props) => {
  const { isStoreLoading } = useShop()
  const { isModalOpen, openModal, portalModal } = useModal()
  const { cart: consumerCart, cartLoading, errors } = useConsumerCart()
  const { orderItems, summary } = consumerCart || {}
  const { cartSession } = useCart()
  const { isCartLoading } = cartSession

  const cartSubtotal = summary?.subtotal.base

  useEffect(() => {
    if (errors) {
      const currentProductErrors = getCurrentProductErrors(errors)
      if (currentProductErrors.length) {
        openModal('cart')
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors])

  const handleOpenCartModal = () => {
    if (isCartLoading || cartLoading) return
    openModal('cart')
  }

  const handleViewCart = () => {
    if (isCartLoading || cartLoading) return
    openModal('cart')
  }

  return !isStoreLoading ? (
    <Actions>
      <CartTotalV2
        itemCount={orderItems?.length}
        totalAmount={cartSubtotal || '0.00'}
        handleOpenCartModal={handleOpenCartModal}
      />
      <LoginIcon merchantId={merchantId} />
      <ViewCartButton
        onClick={handleViewCart}
        isCartLoading={isCartLoading || cartLoading}
      />
      <CheckoutButtonV2 />
      {isModalOpen('cart') && portalModal('cart')}
    </Actions>
  ) : null
}

const Actions = styled.div(() => ({
  display: 'flex',
  alignItems: 'center',
  height: '100%',
  gap: '12px'
}))

export default CartActions
