import { useCallback, useEffect, useMemo, useState } from 'react'
import {
  PaymentElement,
  Elements,
  useStripe,
  useElements
} from '@stripe/react-stripe-js'
import { loadStripe, StripeElementsOptionsMode } from '@stripe/stripe-js'
import { useMutation } from '@apollo/client'
import { INTEND_PAYMENT_FOR_CART } from 'shop/client'
import {
  useModal,
  useShop,
  useConsumerCart,
  useAppContent,
  useMerchantStore,
  useAccount
} from 'shop/hooks'
import styled from '@emotion/styled'
import {
  Button,
  CheckoutNavbar,
  redirectUrl,
  StyledHeading
} from 'shop/components'
import { formatMoney } from 'shop/components/Cart/utils'
import SpinnerModal from 'shop/components/Loader/SpinnerModal'
import AlertMessage from '../components/Cart/AlertMessage'
import { LockIcon } from 'shop/assets/icons'
import { CheckoutActions } from 'shop/components/Checkout/FormElements'
import appearance, { termsOptions } from '../components/Payment/elementStyles'
import { TrackableEvent, trackAction, usePageViewTracker } from 'tracker'
import { validateCart } from 'shop/components/Checkout/Network'
import { CartValidation } from 'shop/components/Checkout/types'
import { returnStoreBaseAddress } from 'shop/components/Checkout/utils'
import { ApolloClient, ApolloError } from '@apollo/client'
import ErrorModal from 'shop/components/Modal/ErrorModal'
import { useHistory } from 'react-router-dom'
import {
  createEcommEventDataFromConsumerCart,
  slerpGA4EcommTrackAddPaymentInfo,
  merchantGA4EcommTrackAddPaymentInfo
} from 'tracker/GA/ecommerce'
import { IoIosArrowBack as BackArrowIcon } from 'react-icons/io'
import { CartV2 } from 'shop/components/CartV2'
import { ConsumerCart } from 'shop/types/cart'
import Theme, { StyledHTMLElement } from 'shop/theme/types'
import { FulfillmentDetails } from 'shop/components/CartV2/FulfillmentDetails'
import {
  checkHandleUnauthorizedError,
  getErrorMessage
} from 'shop/utils/common'
import { CheckboxInput } from 'shop/components/Inputs'
import { trackGA4CustomSaveCardDetails } from 'tracker/GA/custom'
import { ApiError } from 'shop/types'
import DiscountErrorModal from 'shop/components/ErrorModals/DiscountErrorModal'
import { usePay } from 'shop/hooks/usePay'

const PaymentPage = () => {
  const { getConsumerCart } = useConsumerCart()
  const { customerId, merchant, currentStore, config } = useShop()
  const [paymentErrorMessage, setPaymentErrorMessage] = useState<string>('')
  const [saveCardDetails, setSaveCardDetails] = useState(false)
  const [payForCartResponse, setPayForCartResponse] = useState<{
    transactionId: string
    connectAccountId: string
    clientSecret?: never
  }>()

  const history = useHistory()
  const { merchantName } = useAppContent()
  const [transactionId, setTransactionId] = useState('')
  const [consumerCart, setConsumerCart] = useState<ConsumerCart>()

  // Track page view
  usePageViewTracker()

  const publishableKey: string =
    process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY || ''

  const [getPaymentIntent, { data, loading: isPaymentIntentLoading }] =
    useMutation(INTEND_PAYMENT_FOR_CART)

  useEffect(() => {
    // only update data if we have response to stop undefined triggering reloads.
    if (!!data) setPayForCartResponse(data.payForCart)
  }, [data])

  const fetchPaymentIntent = (cartId: string, savePaymentMethod: boolean) => {
    getPaymentIntent({
      variables: { cartId, savePaymentMethod }
    }).catch((error: ApiError) => {
      const { graphQLErrors } = error
      const errorContent =
        graphQLErrors && graphQLErrors[0]
          ? graphQLErrors[0]?.message
          : 'Cannot take payments at the moment.'
      setPaymentErrorMessage(errorContent)
    })
  }

  useEffect(() => {
    const fetchCart = () => {
      getConsumerCart()
        .then((consCart) => {
          if (!consCart || !consCart.summary.total) return history.push('/')

          setConsumerCart(consCart)

          return fetchPaymentIntent(consCart.id, saveCardDetails)
        })
        .catch((error: ApolloError) => {
          const { graphQLErrors } = error

          checkHandleUnauthorizedError(
            error,
            config.domain,
            currentStore,
            history
          )

          if (graphQLErrors?.length) {
            const errorContent =
              getErrorMessage(graphQLErrors) ||
              'Cannot take payments at the moment.'
            setPaymentErrorMessage(errorContent)
          }
        })
    }

    fetchCart()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (payForCartResponse?.transactionId) {
      setTransactionId(payForCartResponse.transactionId)
    }
  }, [payForCartResponse?.transactionId])

  const handleCheckboxClick = useCallback(
    (updatedSaveCardDetails: boolean) => {
      if (!consumerCart?.id || !merchant?.id) return
      setSaveCardDetails(updatedSaveCardDetails)
      fetchPaymentIntent(consumerCart.id, updatedSaveCardDetails)
      trackGA4CustomSaveCardDetails(
        customerId,
        merchant.id,
        updatedSaveCardDetails
      )
    },
    [consumerCart?.id, customerId, merchant?.id]
  )

  const stripeLoadOptions = useMemo(() => {
    return payForCartResponse?.connectAccountId
      ? { stripeAccount: payForCartResponse?.connectAccountId }
      : {}
  }, [payForCartResponse?.connectAccountId])

  const stripePromise = useMemo(
    () => loadStripe(publishableKey, stripeLoadOptions),
    [publishableKey, stripeLoadOptions]
  )
  const stripeOptions: StripeElementsOptionsMode = useMemo(
    () => ({
      appearance: appearance,
      clientSecret: payForCartResponse?.clientSecret,
      loader: 'auto'
    }),
    [payForCartResponse?.clientSecret]
  )

  if (!!paymentErrorMessage)
    return (
      <ErrorModal
        errorCode={paymentErrorMessage}
        primaryButton={{
          onClick: () => history.push('/checkout'),
          message: 'Back to Checkout',
          icon: <BackArrowIcon />
        }}
      />
    )

  if (!consumerCart || !payForCartResponse) return <SpinnerModal />

  const {
    fulfillment,
    deliveryAddress,
    orderItems,
    summary,
    orderNotes,
    additionalItems,
    dropoffNotes,
    store
  } = consumerCart

  const storeAddress = returnStoreBaseAddress(store.address)

  const fulfillmentType = fulfillment.type

  return (
    <Elements stripe={stripePromise} options={stripeOptions}>
      <Container>
        <CheckoutNavbar />
        <PaymentContentWrapper>
          <PaymentForm
            {...{
              transactionId,
              consumerCart,
              saveCardDetails,
              isPaymentIntentLoading,
              handleCheckboxClick
            }}
          />
          <CartContainer>
            <CartV2
              type={'OrderSummary'}
              orderItems={orderItems}
              summary={summary}
              orderNotes={orderNotes}
              fulfillmentType={fulfillmentType}
              additionalItems={additionalItems}
            />
            <FulfillmentDetails
              fulfillment={fulfillment}
              storeName={store.name}
              merchantName={merchantName}
              deliveryAddress={deliveryAddress}
              storeAddress={storeAddress}
              dropoffNotes={dropoffNotes}
            />
          </CartContainer>
        </PaymentContentWrapper>
      </Container>
    </Elements>
  )
}

type PaymentFormProps = {
  transactionId: string
  consumerCart: ConsumerCart
  saveCardDetails: boolean
  handleCheckboxClick: (arg: boolean) => void
  isPaymentIntentLoading: boolean
}

type PaymentMethod = 'apple_pay' | 'google_pay' | 'card'

const PaymentForm = ({
  transactionId,
  consumerCart,
  saveCardDetails,
  handleCheckboxClick,
  isPaymentIntentLoading
}: PaymentFormProps) => {
  const { useShopClient } = useShop()
  const stripe = useStripe()
  const elementsInstance = useElements()
  const { merchantName } = useAppContent()
  const { storeName } = useMerchantStore()
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const [isProcessingPayment, setIsProcessingPayment] = useState(false)
  const [isStripeReady, setIsStripeReady] = useState(false)
  const { summary, id: cartId } = consumerCart
  const total = summary.total.discounted ?? summary.total.base
  const [hasPaymentFormLoaded, setHasPaymentFormLoaded] = useState(false)
  const [showErrorModal, setShowErrorModal] = useState(false)
  const [selectedPaymentMethod, setSelectedPaymentMethod] =
    useState<PaymentMethod>()
  const { isUserLoggedIn } = useAccount()
  const isLoggedIn = isUserLoggedIn()
  const client = useShopClient()
  const { removeDiscountConsumerCart, cart } = useConsumerCart()
  const history = useHistory()
  const { lockCart } = usePay({ client, cartId })

  useEffect(() => {
    if (isPaymentIntentLoading) return
    elementsInstance?.fetchUpdates()
  }, [isPaymentIntentLoading])

  const { timeSlotModal, isModalOpen, openModal } = useModal()
  const [cartValidation, setCartValidation] = useState<CartValidation | null>(
    null
  )
  const SECOND = 1000

  const handleSubmit = async (event: React.SyntheticEvent) => {
    event.preventDefault()

    if (!stripe || !elementsInstance) return

    setIsProcessingPayment(true)

    // Express payment methods must quickly progress to stripe.confirmPayment.
    // Due to them having the pop up window, we have time to complete the lockCart(true) / payForCart.
    // Card details have no such restrictions and so we can "await"
    if (selectedPaymentMethod === 'card') {
      // without the await, the confirmPayment can complete before the lockCart and cause an error.
      // lock cart
      await lockCart(true)
    } else {
      // lock cart
      lockCart(true)
    }

    stripe
      .confirmPayment({
        elements: elementsInstance,
        confirmParams: {
          return_url: redirectUrl(transactionId, true)
        },
        redirect: 'if_required'
      })
      .then((result) => {
        if (!!result.error?.message) {
          setErrorMessage(result.error.message)
          // unlock cart
          lockCart(false).then(() => {
            setIsProcessingPayment(false)
          })
          return
        }
        if (
          !!result.paymentIntent?.canceled_at ||
          !!result.paymentIntent?.cancellation_reason
        ) {
          const paymentIntentErrorMessage =
            result.paymentIntent.cancellation_reason ||
            'Cannot process your payment at the moment. Please try again later.'
          setErrorMessage(paymentIntentErrorMessage)
          // unlock cart
          lockCart(false).then(() => {
            setIsProcessingPayment(false)
          })
          return
        }

        history.push(`/purchase/${transactionId}`)
      })
      .catch((err) => {
        console.error(err)
        if (err.message === 'timeslot') return
        setErrorMessage(
          'Cannot process your payment at the moment. Please try again later.'
        )
        // unlock cart
        lockCart(false).then(() => {
          setIsProcessingPayment(false)
        })
      })
  }

  const verifyCart = (client: ApolloClient<object>, cartId: string) => {
    if (!isModalOpen('timeslot')) {
      // Network validation
      validateCart(client, cartId, 1).then(({ data }) => {
        if (!data.validateCart.valid) {
          setCartValidation(data.validateCart)
          if (!!data.validateCart.discountWarningsNew.length) {
            setShowErrorModal(true)
            return
          }
          openModal('timeslot')
        }
      })
    }
  }

  const initPollCartVerification = (
    client: ApolloClient<object>,
    cartId: string
  ) =>
    setInterval(() => {
      verifyCart(client, cartId)
    }, 30 * SECOND)

  useEffect(() => {
    let pollCartVerificationInterval: NodeJS.Timeout
    // Validate timeslot on load
    verifyCart(client, cartId)

    // Validate timeslot when next minute starts and every 30 seconds there from
    const currentSeconds = new Date().getSeconds()
    setTimeout(
      () => {
        verifyCart(client, cartId)
        pollCartVerificationInterval = initPollCartVerification(client, cartId)
      },
      (60 - currentSeconds + 1) * SECOND
    )

    return () => {
      if (pollCartVerificationInterval) {
        clearInterval(pollCartVerificationInterval)
      }
    }
  }, [])

  return (
    <form onSubmit={handleSubmit}>
      {isModalOpen('timeslot') &&
        cartValidation &&
        timeSlotModal(cartValidation)}
      {showErrorModal && cartValidation && (
        <DiscountErrorModal
          errorCode={cartValidation.discountWarningsNew[0].validationType}
          origin={'pay'}
          checkoutRemoveDiscount={removeDiscountConsumerCart}
          storeSlug={cart?.store.slug}
        />
      )}
      <FormContainer>
        <MainHeading>Payment</MainHeading>
        {errorMessage && (
          <AlertMessage type='error' heading={errorMessage || ''} />
        )}

        <FormSection noBorder>
          <PaymentElement
            onReady={() => {
              setIsStripeReady(true)
              setHasPaymentFormLoaded(true)
            }}
            id='stripe-payment-element'
            options={{
              paymentMethodOrder: ['apple_pay', 'google_pay', 'card'],
              terms: termsOptions
            }}
            onChange={({ value: { type }, complete }) => {
              setSelectedPaymentMethod(type as PaymentMethod)
              const event = TrackableEvent.PaymentSelectPaymentMethod
              trackAction(
                {
                  category: 'Payment',
                  action: 'Selected payment method',
                  label: type
                },
                { event }
              )
              if (!!complete && merchantName && storeName) {
                const ecommerceEventData = createEcommEventDataFromConsumerCart(
                  consumerCart,
                  merchantName,
                  storeName
                )
                const paymentEventData = {
                  ...ecommerceEventData,
                  payment_type: type
                }
                slerpGA4EcommTrackAddPaymentInfo(paymentEventData)
                merchantGA4EcommTrackAddPaymentInfo(paymentEventData)
              }
            }}
          />
          {isLoggedIn && hasPaymentFormLoaded && (
            <InputWrapper>
              <CheckboxInput
                disabled={false}
                type='checkbox'
                checked={saveCardDetails}
                onChange={() => handleCheckboxClick(!saveCardDetails)}
              />
              Save card for future orders
            </InputWrapper>
          )}
          {isStripeReady && (
            <PaymentActions>
              <Button
                onClick={() => {
                  const event = TrackableEvent.PaymentPayButtonClicked
                  trackAction(
                    { category: 'Payment', action: 'Clicked pay button' },
                    { event }
                  )
                }}
                type='submit'
                testId='paymentButton'
                disabled={
                  !stripe ||
                  !elementsInstance ||
                  isProcessingPayment ||
                  isPaymentIntentLoading
                }
                icon={<Icon />}
              >
                <>{`Pay ${formatMoney(Number(total))}`}</>
              </Button>
            </PaymentActions>
          )}
        </FormSection>
      </FormContainer>
    </form>
  )
}

const FormContainer = styled.div(({ theme }: any) => ({
  width: '100%'
}))

const Icon = styled(LockIcon)(() => ({
  display: 'inline-block',
  verticalAlign: 'bottom'
}))

const FormSection = styled.div(
  ({ theme, mobileOnly, noBorder = false }: any) => ({
    borderBottom: noBorder ? 'none' : '8px solid #fafafa',
    minHeight: '300px',
    [theme.mediaQueries.viewport4]: {
      minHeight: '235px',
      display: mobileOnly ? 'none' : 'block',
      padding: '0',
      borderBottom: '0'
    },
    [theme.mediaQueries.viewport8]: {
      minHeight: '300px'
    }
  })
)

const Container = styled.div(() => ({
  minHeight: '100vh',
  backgroundColor: '#F5F5F5',
  position: 'relative'
}))

const MainHeading = styled(StyledHeading)(({ theme }: any) => ({
  fontSize: '20px',
  fontWeight: 'bold',
  margin: '0 0 20px',
  fontFamily: theme.fonts.heading.family,
  [theme.mediaQueries.viewport6]: { padding: '0', fontSize: '24px' }
}))

const PaymentContentWrapper = styled.div(({ theme }: any) => ({
  minHeight: 'min-content',
  position: 'relative',
  margin: '0 auto',
  width: '100%',
  maxWidth: '1470px',
  flexDirection: 'column',
  display: 'grid',
  gap: '12px',
  paddingBottom: '90px',
  height: '100%',
  [theme.mediaQueries.viewport4]: {
    padding: '40px 32px 100px',
    gap: '24px'
  },
  [theme.mediaQueries.viewport8]: {
    gridTemplateColumns: 'minmax(0, 1fr) minmax(0, 1fr)',
    gap: '40px',
    padding: '40px',
    justifyContent: 'center'
  },
  '& > form': {
    height: 'min-content',
    '> div': {
      padding: '24px 16px',
      backgroundColor: 'white',
      [theme.mediaQueries.viewport4]: {
        borderRadius: '12px',
        padding: '32px 16px'
      },
      [theme.mediaQueries.viewport6]: {
        padding: '32px'
      }
    }
  }
}))

const PaymentActions = styled(CheckoutActions)<
  StyledHTMLElement,
  Required<Theme>
>(({ theme }) => ({
  zIndex: 2,
  [theme.mediaQueries.viewport6]: {
    marginTop: '32px'
  }
}))

const CartContainer = styled.div<StyledHTMLElement, Required<Theme>>(
  ({ theme }) => ({
    minWidth: 0,
    '& > div ': {
      backgroundColor: 'white',
      padding: '16px 16px 8px 16px',
      height: 'min-content',
      [theme.mediaQueries.viewport4]: {
        borderRadius: '12px',
        padding: '32px 32px 12px 32px'
      },
      '&:nth-of-type(2)': {
        marginTop: '12px',
        [theme.mediaQueries.viewport4]: {
          marginTop: '24px'
        },
        [theme.mediaQueries.viewport8]: {
          marginTop: '32px'
        }
      }
    }
  })
)

const InputWrapper = styled.div(({ theme }: any) => ({
  display: 'flex',
  gap: '7px',
  margin: '20px 0',
  flexGrow: 1,
  fontSize: '14px',
  lineHeight: '18px',

  '& input': {
    '&[type="checkbox"]': {
      '&:checked': {
        borderColor: theme.colors.primary
      },
      borderColor: `#e6e6e6`
    }
  }
}))

export default PaymentPage
