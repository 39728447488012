import styled from '@emotion/styled'
import { Clear, ellipsis } from '../commonStyles'
import { CartWarningMessage, Discount, DiscountWarning } from 'shop/types/cart'
import { useCheckout, useConsumerCart, useModal } from 'shop/hooks'
import { StyledHTMLElement } from 'shop/theme/types'
import { findErrorWarning, formatMoney } from 'shop/components/Cart/utils'
import { IoInformationCircleSharp as InfoIcon } from 'react-icons/io5'
import { useEffect, useState } from 'react'
import { trackRemoveDiscountCodeConsumerCart } from 'shop/components/Checkout/tracking/helpers'
import Spinner from 'shop/components/Loader/Spinner'
import { isDiscountWarningCode } from 'shop/components/Checkout'

interface Props {
  discount: Discount
  isEditable: boolean
}

interface WarningMessageProps {
  text: string
  onClick?: () => void
}

/** Renders soft validation in discount UI */
const WarningMessage: React.FC<WarningMessageProps> = ({ text, onClick }) => (
  <WarningMessageContainer onClick={onClick} hasOnClick={!!onClick}>
    <StyledCheckmark />
    <DiscountText>{text}</DiscountText>
  </WarningMessageContainer>
)

const DiscountBreakdownItem = ({
  discount: { code, description },
  isEditable = false
}: Props) => {
  const [isTagExpanded, setIsTagExpanded] = useState(false)
  const [isRemovingDiscount, setIsRemovingDiscount] = useState(false)
  const [validationWarnings, setValidationWarnings] = useState(
    [] as DiscountWarning[]
  )
  const { removeDiscountConsumerCart } = useConsumerCart()
  const { discountErrorWarnings } = useCheckout()

  const onDiscountRemoveClick = (e: React.MouseEvent) => {
    e.stopPropagation()
    if (isRemovingDiscount) return
    setIsRemovingDiscount(true)
    removeDiscountConsumerCart()
      .then(() => {
        // Track discount code when removed
        trackRemoveDiscountCodeConsumerCart(code)
      })
      .finally(() => {
        setIsRemovingDiscount(false)
      })
  }
  const { openModal } = useModal()

  useEffect(() => {
    const currentValidationWarnings = discountErrorWarnings?.filter((warning) =>
      findErrorWarning([warning], isDiscountWarningCode)
    ) as DiscountWarning[]

    setValidationWarnings(currentValidationWarnings)
  }, [discountErrorWarnings])

  const generateMessages = (warning: DiscountWarning) => {
    const messages: {
      text: string
      onClick?: () => void
    }[] = []
    switch (warning.message) {
      case CartWarningMessage.DISCOUNT_MINIMUM_VALUE_NOT_MET:
        messages.push({
          text: `Minimum spend ${formatMoney(parseFloat(warning.minimumValue))}`
        })
        break
      case CartWarningMessage.CUSTOMER_LOGIN_REQUIRED:
        messages.push({
          text: 'Log in required',
          onClick: () => openModal('login')
        })
        break
      case CartWarningMessage.NO_TARGET_CATEGORIES_IN_CART:
        messages.push({
          text: 'Add a product from an eligible category to your basket'
        })
        break
      case CartWarningMessage.NO_TARGET_PRODUCTS_IN_CART:
        messages.push({ text: 'Add eligible product(s) to your basket' })
        break
      case CartWarningMessage.NO_TARGET_VARIANTS_IN_CART:
        messages.push({
          text: 'Add an eligible product variant to your basket'
        })
        break
      case CartWarningMessage.DISCOUNT_DELIVERY_ONLY:
        messages.push({ text: 'Switch to Delivery' })
        break
      case CartWarningMessage.DISCOUNT_NOT_APPLICABLE_TO_STORE:
        messages.push({ text: 'Choose valid store' })
        break
      default:
        break
    }
    return messages
  }

  return (
    <>
      <Container>
        <DiscountContent
          hasValidationWarnings={!!validationWarnings.length}
          data-testid='discount'
        >
          <DiscountInfo onClick={() => setIsTagExpanded(!isTagExpanded)}>
            <DiscountCode>{code}</DiscountCode>
            {!!description && (
              <DiscountText isTagExpanded={isTagExpanded}>
                {description}
              </DiscountText>
            )}
            {isEditable && !isRemovingDiscount && (
              <Clear
                top='7px'
                data-testid='remove-discount-button'
                onClick={onDiscountRemoveClick}
                position={'initial'}
              />
            )}
            {isRemovingDiscount && (
              <SpinnerContainer>
                <Spinner size='18px' />
              </SpinnerContainer>
            )}
          </DiscountInfo>
          {!!validationWarnings.length && (
            <SoftValidationInfo data-testid='soft-validation-warnings'>
              <DiscountText
                fontColor={'black'}
                fontWeight={500}
                isTagExpanded={isTagExpanded}
                onClick={() => setIsTagExpanded(!isTagExpanded)}
              >
                You need to meet the following requirements to use this
                discount.
              </DiscountText>
              <ValidationWarningsContent>
                {validationWarnings.map((warning) =>
                  generateMessages(warning).map(({ text, onClick }, index) => (
                    <WarningMessage
                      key={`${warning.message}-${index}`}
                      text={text}
                      onClick={onClick}
                    />
                  ))
                )}
              </ValidationWarningsContent>
            </SoftValidationInfo>
          )}
        </DiscountContent>
      </Container>
    </>
  )
}

const Container = styled.div(() => ({
  width: '100%'
}))

const SpinnerContainer = styled.div(() => ({
  width: '22px',
  marginLeft: 'auto',
  flexShrink: 0
}))

const DiscountContent = styled.div<
  StyledHTMLElement & { hasValidationWarnings?: boolean }
>(({ hasValidationWarnings }) => ({
  position: 'relative',
  fontSize: '16px',
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'column',
  borderRadius: '12px',
  backgroundColor: hasValidationWarnings ? '#D46B081A' : '#389E0D1A',
  border: hasValidationWarnings ? '1px solid transparent' : '1px solid #389E0D'
}))

const DiscountInfo = styled.div<StyledHTMLElement>(() => ({
  whiteSpace: 'pre',
  display: 'flex',
  alignItems: 'center',
  padding: '7px 12px',
  cursor: 'pointer'
}))

const SoftValidationInfo = styled.div(() => ({
  padding: '0 12px 7px'
}))

const ValidationWarningsContent = styled.div(() => ({}))

const WarningMessageContainer = styled.div<
  StyledHTMLElement & { hasOnClick: boolean }
>(({ hasOnClick = false }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: '4px 0 0',

  ...(hasOnClick
    ? {
        cursor: 'pointer',
        '> p': {
          textDecoration: 'underline',

          '&:hover': {
            color: '#000'
          }
        }
      }
    : {})
}))

const DiscountCode = styled.p(() => ({
  fontWeight: 500,
  ...ellipsis,
  margin: 0,
  flexShrink: 0,
  marginRight: '6px',
  lineHeight: '22px'
}))

const StyledCheckmark = styled(InfoIcon)(() => ({
  color: '#D46B08',
  margin: '1px 4px 0 0',
  flexShrink: 0,
  alignSelf: 'flex-start'
}))

const DiscountText = styled.p<
  StyledHTMLElement & {
    fontWeight?: number
    fontColor?: string
    padding?: string
    isTagExpanded?: boolean
  }
>(({ fontWeight = 400, fontColor = '#59595A', padding, isTagExpanded }) => ({
  color: fontColor,
  fontWeight: fontWeight,
  padding: padding || 'initial',
  fontSize: '14px',
  lineHeight: '16px',
  ...(isTagExpanded
    ? { textWrap: 'wrap', whiteSpace: 'pre-wrap' }
    : { ...ellipsis, whiteSpace: 'nowrap' }),
  margin: 0,
  cursor: 'pointer',
  marginRight: '12px',
  maxWidth: '100%'
}))

export default DiscountBreakdownItem
