import { SelectedModifiers } from '../Landing'
import { ProductImageURLs } from './imageUtils'

export const ALLERGENS = {
  celery: 'Celery',
  cereals: 'Cereals containing gluten',
  crustaceans: 'Crustaceans',
  eggs: 'Eggs',
  fish: 'Fish',
  gluten: 'Gluten',
  lupin: 'Lupin',
  milk: 'Milk',
  molluscs: 'Molluscs',
  mustard: 'Mustard',
  nuts: 'Nuts',
  peanuts: 'Peanuts',
  sesame: 'Sesame',
  soybeans: 'Soybeans',
  sulphur: 'Sulphur dioxide and sulphites',
  treenuts: 'Tree nuts',
  wheat: 'Wheat'
}

export type Allergens = (typeof ALLERGENS)[keyof typeof ALLERGENS]

export type OptionsAllergens = Record<string, Record<string, Array<Allergens>>>

export type ProductOptions = {
  name: string
  values: string[]
}

type CalorieInfo = 'calories_per_serving'

export type CalorieData = Record<CalorieInfo, number>

export interface AppliedModifiers {
  [key: string]: SelectedModifiers
}
export interface Product {
  additionalImages: ProductImageURLs[]
  allergens: Array<Allergens>
  minPrice: Price
  startPrice: Price
  calorieData: {
    caloriesPerServing: number
  } | null
  defaultVariantId: string
  description: string
  id: string
  image: ProductImageURLs
  inStock: boolean
  limit: number | null
  modifierGroups: ProductModifierGroup[]
  name: string
  options: ProductOptions[]
  seoDescription: string | null
  variants: ProductVariant[]
}

export type ProductVariant = {
  extraPrice: Price
  id: string
  inStock: boolean
  limit: number | null
  name: string
  options: ProductOptions[]
  price: Price
  restrictions: {
    alcoholic: boolean
  }
  options_allergens: OptionsAllergens
}

export type ProductModifierGroup = {
  id: string
  minimum: number | null
  modifiers: ProductModifier[]
  maximum: number | null
  name: string
}

export type ProductModifier = {
  id: string
  image: ProductImageURLs
  inStock: boolean
  name: string
  price: Price
  restrictions: {
    alcoholic: boolean
  }
  allergens: Array<Allergens>
}

export type FulfillmentType = 'pickup' | 'delivery' | 'order_at_table' | null

export interface SelectedOption {
  name: string
  value: string
}

export type Price = {
  basePrice: string
}
