import styled from '@emotion/styled'
import Theme, { StyledHTMLElement } from 'shop/theme/types'
import { useLayoutEffect, useState } from 'react'
import { IoChevronDownCircleOutline as ChevronIcon } from 'react-icons/io5'
import { PartnerStore } from '../types'
import { formatPartnerAddressToString } from './utils'
import OpeningHoursSection from './OpeningHoursSection'
import ExpandableDiv from 'shop/components/ExpandableContainer/ExpandableDiv'
import FulfillmentInfo from './FulfillmentInfo'

type Props = {
  store: PartnerStore
  isDeliveryEnabled: boolean
  isPickupEnabled: boolean
  onLoadDateTime: Date
}

const LandingStoreListItem = ({
  store,
  isDeliveryEnabled,
  isPickupEnabled,
  onLoadDateTime
}: Props) => {
  const [lineClamp, setLineClamp] = useState<number>(2)
  const [descriptionClampedHeight, setDescriptionClampedHeight] = useState<
    number | null
  >(null)
  const [isStoreInfoExpanded, setIsStoreInfoExpanded] = useState<boolean>(false)

  useLayoutEffect(() => {
    // set height of clamped <p> so we can animate it later
    const descriptionEl = document.getElementById(`${store.slug}-description`)
    if (descriptionEl) {
      const descriptionElHeight = descriptionEl.clientHeight
      setDescriptionClampedHeight(descriptionElHeight)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useLayoutEffect(() => {
    // delay lineClamp so we can animate the collapse of the text
    if (isStoreInfoExpanded) return
    const timeout = setTimeout(() => {
      setLineClamp(2)
    }, 500)

    return () => {
      clearTimeout(timeout)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isStoreInfoExpanded])

  const handleExpandToggle = () => {
    // set lineClamp to 0 here before expand render
    if (!isStoreInfoExpanded) setLineClamp(0)
    setIsStoreInfoExpanded(!isStoreInfoExpanded)
  }

  return (
    <ListItem>
      <StoreInfo>
        <HeaderContent isStoreInfoExpanded={isStoreInfoExpanded}>
          <div>
            <h3>{store.name}</h3>
            <ChevronIcon
              data-testid={`${store.slug}-expand-icon`}
              onClick={handleExpandToggle}
            />
          </div>
          <div>{!!store.address.zip && <span>{store.address.zip}</span>}</div>
        </HeaderContent>
        <BodyContent>
          {!!store.description && (
            <ExpandableDiv
              isExpanded={isStoreInfoExpanded}
              collapsedHeight={
                !!descriptionClampedHeight
                  ? descriptionClampedHeight
                  : 'content'
              }
            >
              <Description
                id={`${store.slug}-description`}
                lineClamp={lineClamp}
                isStoreInfoExpanded={isStoreInfoExpanded}
                onClick={!isStoreInfoExpanded ? handleExpandToggle : () => {}}
              >
                {store.description}
              </Description>
            </ExpandableDiv>
          )}
          <ExpandableDiv
            id={`${store.slug}-expandable-info`}
            isExpanded={isStoreInfoExpanded}
            collapsedHeight={0}
          >
            <ContentContainer
              style={{
                paddingTop: !!store.description ? '16px' : '8px'
              }}
            >
              <ContentItem>
                <h4>Address</h4>
                <p>{formatPartnerAddressToString(store.address)}</p>
              </ContentItem>
              <ContentItem>
                <h4>Opening Hours</h4>
                <OpeningHoursSection
                  openingHours={store.openingHours}
                  storeSlug={store.slug}
                />
              </ContentItem>
            </ContentContainer>
          </ExpandableDiv>
        </BodyContent>
      </StoreInfo>
      <Divider />
      <FulfillmentInfo
        store={store}
        onLoadDateTime={onLoadDateTime}
        isDeliveryEnabled={isDeliveryEnabled}
        isPickupEnabled={isPickupEnabled}
      />
    </ListItem>
  )
}

const ListItem = styled.li(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  padding: '16px 12px',
  border: '1px solid #D9D9D9',
  borderRadius: '12px',
  boxShadow: `0px 3px 8px -4px rgba(0, 0, 0, 0.12)`
}))

const HeaderContent = styled.div<
  StyledHTMLElement & { isStoreInfoExpanded: boolean },
  Required<Theme>
>(({ theme, isStoreInfoExpanded }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '4px',
  fontFamily: theme.fonts['heading'].family,

  '& div': {
    display: 'flex',
    gap: '10px',
    justifyContent: 'space-between',

    // store name
    '>h3': {
      fontSize: '18px',
      lineHeight: '21px',
      fontWeight: 600
    },

    '>svg': {
      minHeight: '24px',
      minWidth: '24px',
      cursor: 'pointer',
      rotate: isStoreInfoExpanded ? '180deg' : '0deg',
      transition: 'all 500ms ease-in-out',
      // disable blue hightlight of user clicks
      WebkitTapHighlightColor: 'transparent',

      '&:hover': {
        opacity: 0.8
      },
      '&:active': {
        opacity: 0.6
      }
    },

    // postcode
    '>span': {
      fontSize: '16px',
      color: '#8B8B8B',
      lineHeight: '19px',
      fontWeight: 600
    }
  }
}))

const Description = styled.p<
  StyledHTMLElement & { isStoreInfoExpanded: boolean; lineClamp: number },
  Required<Theme>
>(({ theme, isStoreInfoExpanded, lineClamp }) => ({
  fontSize: '14px',
  padding: '0',
  paddingTop: '8px',
  margin: '0',
  fontFamily: theme.fonts['body'].family,
  color: '#59595A',
  cursor: isStoreInfoExpanded ? 'inherit' : 'pointer',
  // set max lines to 2
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
  WebkitLineClamp: lineClamp
}))

const StoreInfo = styled.div(() => ({
  display: 'flex',
  flexDirection: 'column'
}))

const Divider = styled.hr(() => ({
  width: '100%',
  margin: 0,
  border: 0,
  borderBottom: `1px solid #EFEFF0`,
  borderRadius: '1px'
}))

const BodyContent = styled.div<StyledHTMLElement, Required<Theme>>(
  ({ theme }) => ({
    display: 'flex',
    flexDirection: 'column'
  })
)

const ContentContainer = styled.div<StyledHTMLElement, Required<Theme>>(
  ({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '16px'
  })
)

const ContentItem = styled.div<StyledHTMLElement, Required<Theme>>(
  ({ theme }) => ({
    fontFamily: theme.fonts['body'].family,
    '>h4': {
      fontWeight: 600,
      fontSize: '16px',
      margin: 0,
      marginBottom: '4px',
      padding: 0,
      color: '#2A2A2A'
    },

    '& p': {
      margin: 0,
      padding: 0,
      fontSize: '14px',
      color: '#59595A'
    }
  })
)

export default LandingStoreListItem
