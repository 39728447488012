import styled from '@emotion/styled'
import { capitalize } from 'lodash'
import Theme, { StyledHTMLElement } from 'shop/theme/types'
import { OpeningHour } from '../types'

type Props = {
  openingHours: OpeningHour[]
  storeSlug: string
}

const OpeningHoursSection = ({ openingHours, storeSlug }: Props) => {
  return (
    <OpeningHoursList>
      {openingHours.map((openingHour) => {
        const isClosed = openingHour.shifts.length === 0
        const formattedDay = capitalize(openingHour.dayOfWeek.toLowerCase())

        return (
          <OpeningHourItem
            data-testid={`${storeSlug}-${formattedDay}`}
            key={`${storeSlug}-${formattedDay}`}
            isClosed={isClosed}
          >
            <span>{formattedDay}</span>
            <ShiftItems isClosed={isClosed}>
              {isClosed ? (
                <li key={`${storeSlug}-${formattedDay}-closed`}>Closed</li>
              ) : (
                openingHour.shifts.map((shift) => (
                  <li
                    key={`${storeSlug}-${formattedDay}-${shift.startTime}-${shift.endTime}`}
                  >
                    {`${shift.startTime} - ${shift.endTime}`}
                  </li>
                ))
              )}
            </ShiftItems>
          </OpeningHourItem>
        )
      })}
    </OpeningHoursList>
  )
}

const OpeningHoursList = styled.ul<StyledHTMLElement, Required<Theme>>(
  ({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '12px'
  })
)

const OpeningHourItem = styled.li<
  StyledHTMLElement & { isClosed: boolean },
  Required<Theme>
>(({ theme, isClosed }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  fontFamily: theme.fonts['body'].family,
  fontSize: '14px',
  textAlign: 'right',
  color: isClosed ? '#BEBEBE' : '#59595A',
  margin: 0,
  padding: 0,

  [theme.mediaQueries.viewport7]: {
    maxWidth: '260px'
  }
}))

const ShiftItems = styled.ul<
  StyledHTMLElement & { isClosed: boolean },
  Required<Theme>
>(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '6px',
  fontFamily: theme.fonts['body'].family
}))

export default OpeningHoursSection
