import styled from '@emotion/styled'
import { SimpleFormat } from 'shop/utils'

const PartnerDescription = ({
  welcomeMessage,
  welcomeMessageHeader
}: {
  welcomeMessage?: string
  welcomeMessageHeader?: string
}) => {
  const welcomeMessageHasText = !!welcomeMessage?.replace(/<[^>]*>/g, '').trim()

  const welcomeMessageHeaderHasText = !!welcomeMessageHeader
    ?.replace(/<[^>]*>/g, '')
    .trim()

  const hasWelcomeDescription =
    welcomeMessageHasText || welcomeMessageHeaderHasText

  const shouldHaveLineBreak =
    welcomeMessageHeaderHasText && welcomeMessageHeaderHasText

  if (!hasWelcomeDescription) return <Padding />
  return (
    <>
      <Description>
        {welcomeMessageHeaderHasText && (
          <SimpleFormat>{welcomeMessageHeader}</SimpleFormat>
        )}
        {shouldHaveLineBreak && <br />}
        {welcomeMessageHasText && <SimpleFormat>{welcomeMessage}</SimpleFormat>}
      </Description>

      <Divider />
    </>
  )
}

const Padding = styled.div(() => ({
  paddingTop: '16px'
}))

const Description = styled.div(({ theme }: any) => ({
  overflow: 'hidden',
  textOverflow: 'hidden',
  transition: 'all 200ms ease-in',
  color: theme.colors.textBold,
  fontWeight: theme.fonts.heading.weight,
  fontFamily: theme.fonts.heading.family,
  fontSize: '8px',
  '& ul': {
    listStyleType: 'revert',
    padding: 'revert'
  },
  '*': {
    margin: '0 !important'
  },
  h1: {
    fontSize: '20px',
    fontWeight: 600,
    lineHeight: 'normal'
  },
  'h6,p': {
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: 'normal'
  },
  a: {
    color: 'inherit',
    fontWeight: 700
  },
  [theme.mediaQueries.viewport7]: {
    padding: '0 16px',
    fontSize: '10px'
  }
}))
const Divider = styled.hr(({ theme }: any) => ({
  margin: '24px 0',
  border: 0,
  borderBottom: '1px solid #f5f5f5',

  [theme.mediaQueries.viewport7]: {
    margin: '32px 0'
  }
}))
export default PartnerDescription
