import { useState, useEffect, useCallback, useRef, useMemo } from 'react'
import { useShop, usePrevious, useConsumerCart } from 'shop/hooks'
import { QUERY_GET_STORE_CATEGORIES, GetCategoriesVariables } from 'shop/client'
import { ProductList } from 'shop/components'
import { Categories } from './Categories'
import { getCurrentCartId } from 'shop/components/Cart/utils'
import { includesCurrentCategory } from 'shop/components/Shop/Categories'

import {
  trackFBPixeltrackProductListViewed,
  trackGA4ProductListViewed
} from 'tracker'
import { CategoryWithProducts } from 'shop/components/Shop/Categories/types'
import { filterCatProductsBySearchTerm } from 'shop/components/Product/utils'
import { ASAP_ORDER } from 'shop/types'
import { StickyCard } from './StickyCard'
import { useShopPage } from 'shop/hooks'

interface ShopBodyProps {
  slug: string
  merchant: string
}

export const ShopBody = ({ slug, merchant: merchantSlug }: ShopBodyProps) => {
  const mounted = useRef(true)
  const {
    config,
    merchant,
    useShopClient,
    cartSession,
    setIsStoreLoading,
    setIsProductsLoading
  } = useShop()

  const { handleAutoScrolling, isSearching, searchTerm } = useShopPage()

  const client = useShopClient()
  const { isCartLoading } = cartSession
  const { cart, cartLoading } = useConsumerCart()
  const [categories, setCategories] = useState<CategoryWithProducts[]>([])
  const [availableCategories, setAvailableCategories] = useState<
    CategoryWithProducts[]
  >([])
  const [lastSelectedCategory, setLastSelectedCategory] = useState<string>('')
  const [selectedCategory, setSelectedCategory] = useState<string>('')

  const [shouldReloadProducts, setShouldReloadProducts] = useState(false)

  const { fulfillment, store } = cart || {}
  const cartId = getCurrentCartId(config.domain)

  /** Previous cart values used in reload logic */
  const oldCartValues = usePrevious({
    fulfillmentFrom: fulfillment?.window.from,
    fulfillmentType: fulfillment?.type,
    storeSlug: cart?.store.slug,
    orderType: fulfillment?.orderType,
    storeId: cart?.store.id
  })

  /** Current cart values used in reload logic */
  const cartDependencies = useMemo(() => {
    return {
      fulfillmentFrom: fulfillment?.window.from,
      fulfillmentType: fulfillment?.type,
      orderType: fulfillment?.orderType,
      storeId: store?.id
    }
  }, [fulfillment, store?.id])

  /** Memoised slug value used in reload logic  */
  const storeSlug = useMemo(() => store?.slug || slug, [slug, store?.slug])

  /** Changing store slug should reload products, return if cart (cart changes handled by useEffect below) */
  useEffect(() => {
    if (cartId || oldCartValues?.storeSlug === storeSlug) return
    setShouldReloadProducts(true)
  }, [storeSlug, cartId, oldCartValues?.storeSlug])

  /** Changing cart date/time/types/store triggers a reload in the shops products */
  useEffect(() => {
    if (
      cart &&
      ((cartDependencies.orderType !== ASAP_ORDER &&
        oldCartValues?.fulfillmentFrom !== cartDependencies.fulfillmentFrom) ||
        oldCartValues?.fulfillmentType !== cartDependencies.fulfillmentType ||
        oldCartValues?.orderType !== cartDependencies.orderType ||
        oldCartValues?.storeId !== cartDependencies.storeId)
    ) {
      setShouldReloadProducts(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartDependencies, oldCartValues])

  useEffect(() => {
    // Load the categories with products data
    // cartIds are intialised when coming from the Landing page
    // and hence should not attempt to load any categories until the cart has intialised
    // if we already have category data but are updating the cart, exit.
    // otherwise force a reload if we want to through shouldReloadProducts.
    if (
      (isCartLoading ||
        cartLoading ||
        (cartId && !cart) ||
        categories.length) &&
      !shouldReloadProducts
    )
      return

    let categoryVariables: GetCategoriesVariables

    // no cart and no cartId means deeplinking
    if (!cart) {
      categoryVariables = {
        storeSlug: slug
      }
    } else {
      const fulfillmentType = fulfillment?.type

      categoryVariables = {
        fulfillmentDatetime: !fulfillment
          ? null
          : fulfillment.orderType === ASAP_ORDER
            ? null
            : fulfillment?.window.from,
        fulfillmentType: fulfillmentType
      }

      if (store?.id) {
        categoryVariables = {
          ...categoryVariables,
          storeId: store.id
        }
      } else {
        categoryVariables = {
          ...categoryVariables,
          storeSlug: slug
        }
      }
    }
    setIsProductsLoading(true)
    client
      .query({
        query: QUERY_GET_STORE_CATEGORIES,
        variables: categoryVariables,
        fetchPolicy: 'network-only'
      })
      .then((result) => {
        setCategories(result.data.getCategories)
        setIsProductsLoading(false)
        setShouldReloadProducts(false)
        setLastSelectedCategory('')
      })
  }, [cart, isCartLoading, cartLoading, slug, shouldReloadProducts]) // eslint-disable-line

  const track = useCallback(() => {
    if (categories.length > 0 && selectedCategory) {
      // legacy tracking
      trackFBPixeltrackProductListViewed()

      trackGA4ProductListViewed('slerpGA4Tracking')
      trackGA4ProductListViewed('merchantGA4Tracking')
    }
  }, [categories, selectedCategory])

  /** ProductList onLoad */
  useEffect(() => {
    setIsStoreLoading(true)
    track()

    window.scrollTo(0, 0)
    mounted.current = true

    return () => {
      mounted.current = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  /** Once the Merchant has loaded we can assume the useShop hook has loaded.
   * This is to keep functionality of a getMerchant call that has now been removed.
   */
  useEffect(() => {
    if (merchant) {
      setIsStoreLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [merchant])

  /** Sets the initial category selected */
  useEffect(() => {
    if (!mounted.current) return
    if (!availableCategories.length) return

    if (
      isSearching &&
      availableCategories.length > 0 &&
      !includesCurrentCategory(availableCategories, lastSelectedCategory) &&
      lastSelectedCategory !== ''
    ) {
      return setSelectedCategory(availableCategories[0].id)
    }
    if (lastSelectedCategory) return setSelectedCategory(lastSelectedCategory)
    // set the default cateogry to the first in the list that has an id
    const firstCatIndex = availableCategories.findIndex((cat) => cat.id)
    return setSelectedCategory(availableCategories[firstCatIndex].id)
  }, [lastSelectedCategory, availableCategories, isSearching])

  /** Filter Categories & Products further when searching */
  useEffect(() => {
    if (searchTerm && isSearching && categories.length) {
      const categoriesWithFiteredProducts = categories.map((cat) => {
        return {
          ...cat,
          products: filterCatProductsBySearchTerm(
            cat.name,
            cat.products,
            searchTerm
          )
        }
      })

      const filteredCategories = categoriesWithFiteredProducts.filter((cat) => {
        return cat.products.length > 0
      })

      return setAvailableCategories(filteredCategories)
    }

    const filteredCategories = categories.filter((category) =>
      Boolean(category?.products?.length)
    )
    return setAvailableCategories(filteredCategories)
  }, [searchTerm, isSearching, categories])

  const handleSetCategory = (categoryId: string) => {
    setLastSelectedCategory(categoryId)
    setSelectedCategory(categoryId)
  }

  const handleCategoryChange = (categoryId: string) => {
    handleAutoScrolling()
    handleSetCategory(categoryId)

    const element = document.getElementById(`product-category-${categoryId}`)

    if (!element) {
      return
    }

    const yOffset = 150
    const scrollPositon =
      element.getBoundingClientRect().top + window.pageYOffset - yOffset

    window.scrollTo({ top: scrollPositon, behavior: 'smooth' })
  }

  return (
    <StickyCard
      id='shop-body-root'
      hasHeaderOffset
      header={
        <Categories
          selectedCategory={selectedCategory}
          onClick={handleCategoryChange}
          categories={availableCategories}
        />
      }
      content={
        <ProductList
          setActiveCategory={handleSetCategory}
          selectedCategory={selectedCategory}
          categories={availableCategories}
        />
      }
    />
  )
}
