import styled from '@emotion/styled'
import Theme, { StyledHTMLElement } from 'shop/theme/types'
import { IoBagHandleOutline, IoBicycleOutline } from 'react-icons/io5'
import { FulfillmentType } from 'shop/types'
import { PartnerStore } from '../types'
import { formatEarliestSlot } from './utils'
import { useRef } from 'react'
import { useMediaQueries } from 'shop/hooks'
import useResizeObserver from '@react-hook/resize-observer'

type FulfillmentInfoProps = {
  store: PartnerStore
  onLoadDateTime: Date
  isDeliveryEnabled: boolean
  isPickupEnabled: boolean
}

const FulfillmentInfo = ({
  store,
  onLoadDateTime,
  isDeliveryEnabled,
  isPickupEnabled
}: FulfillmentInfoProps) => {
  if (!isDeliveryEnabled && !isPickupEnabled) return <></>

  return (
    <FulfillmentInfoContainer>
      <FulfillmentInfoItemContainer>
        {isDeliveryEnabled && (
          <FulfillmentInfoItem>
            <FulfillmentAvailability
              fulfillmentType='DELIVERY'
              store={store}
              onLoadDateTime={onLoadDateTime}
            />
          </FulfillmentInfoItem>
        )}
        {isPickupEnabled && (
          <FulfillmentInfoItem>
            <FulfillmentAvailability
              fulfillmentType='PICKUP'
              store={store}
              onLoadDateTime={onLoadDateTime}
            />
          </FulfillmentInfoItem>
        )}
      </FulfillmentInfoItemContainer>
      <FulfillmentInfoItemContainer isDeliveryEnabled={isDeliveryEnabled}>
        {isDeliveryEnabled && (
          <FulfillmentInfoItem>
            <FulfillmentLink fulfillmentType='DELIVERY' store={store} />
          </FulfillmentInfoItem>
        )}
        {isPickupEnabled && (
          <FulfillmentInfoItem>
            <FulfillmentLink fulfillmentType='PICKUP' store={store} />
          </FulfillmentInfoItem>
        )}
      </FulfillmentInfoItemContainer>
    </FulfillmentInfoContainer>
  )
}

type FulfillmentAvailabilityOptionProps = {
  fulfillmentType: FulfillmentType
  store: PartnerStore
  onLoadDateTime: Date
}
const FulfillmentAvailability = ({
  fulfillmentType,
  store,
  onLoadDateTime
}: FulfillmentAvailabilityOptionProps) => {
  const filteredOFT = store.orderFulfillmentTypes.filter(
    (oft) =>
      oft.fulfillmentType === fulfillmentType &&
      oft.status === 'ACCEPTING_ORDERS'
  )
  let earliestSlot: {
    from: string
    to: string
  } | null = null
  if (!!filteredOFT.length) earliestSlot = filteredOFT[0].earliestTimeslot
  const formattedEarliestSlot = formatEarliestSlot(earliestSlot, onLoadDateTime)

  return (
    <FulfillmentAvailabilitySlot isUnavailable={!earliestSlot}>
      {formattedEarliestSlot}
    </FulfillmentAvailabilitySlot>
  )
}

type FulfillmentLinkProps = {
  fulfillmentType: FulfillmentType
  store: PartnerStore
}

const FulfillmentLink = ({ fulfillmentType, store }: FulfillmentLinkProps) => {
  const ref = useRef<HTMLAnchorElement>(null)
  const { isMobile, isLargeMobile, isTablet, isDesktop } = useMediaQueries()
  useResizeObserver(ref, ({ target }) =>
    setLargestLinkWidthInCSS(target.clientWidth)
  )

  const isAcceptingOrders = !!store.orderFulfillmentTypes.filter(
    (oft) =>
      oft.fulfillmentType === fulfillmentType &&
      oft.status === 'ACCEPTING_ORDERS'
  ).length

  const iconMap = {
    DELIVERY: <IoBicycleOutline size={'20px'} />,
    PICKUP: <IoBagHandleOutline size={'20px'} />
  }

  /** Set CSS variable "--largest-link-width" to ensure CTAs are the same size */
  const setLargestLinkWidthInCSS = (largestLinkWidth?: number) => {
    if (fulfillmentType === 'DELIVERY') {
      if (largestLinkWidth) {
        const body = document.querySelector('body')
        body?.style.setProperty('--largest-link-width', `${largestLinkWidth}px`)
      }
    }
  }

  // Render different text content at different viewports
  const getLinkContent = (fulfillmentType: FulfillmentType) => {
    const fulfillmentTypeLowerCase = fulfillmentType.toLowerCase()
    // approx. [theme.mediaQueries.viewport4]
    if (isMobile && isLargeMobile) {
      return `View ${fulfillmentTypeLowerCase} Menu`
    }
    // approx.[theme.mediaQueries.viewport7]
    if (isTablet && !isDesktop) {
      return fulfillmentTypeLowerCase
    }
    // approx. [theme.mediaQueries.viewport12]
    if (isDesktop) {
      return `View ${fulfillmentTypeLowerCase} Menu`
    }
    // smallest mobile
    return fulfillmentTypeLowerCase
  }

  return (
    <FulfillmentLinkContainer
      data-testid={`${fulfillmentType}-link`}
      href={`/order/store/${store.slug}?fulfillment_type=${fulfillmentType.toLowerCase()}`}
      ref={ref}
      disabled={!isAcceptingOrders}
    >
      {iconMap[fulfillmentType]}
      <FulfillmentLinkContent>
        <span>{getLinkContent(fulfillmentType)}</span>
      </FulfillmentLinkContent>
    </FulfillmentLinkContainer>
  )
}

const FulfillmentInfoContainer = styled.div<StyledHTMLElement, Required<Theme>>(
  ({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '20px',
    justifyContent: 'space-between'
  })
)

const FulfillmentInfoItemContainer = styled.div<
  StyledHTMLElement & { isDeliveryEnabled?: boolean },
  Required<Theme>
>(({ theme, isDeliveryEnabled = true }) => ({
  display: 'flex',
  gap: '16px',
  alignItems: 'stretch',
  flexDirection: 'column',
  // only set the width when the Pickup is by itself as it has no reference otherwise.
  minWidth: !isDeliveryEnabled ? `var(--largest-link-width)` : 'unset'
}))

const FulfillmentInfoItem = styled.div<StyledHTMLElement, Required<Theme>>(
  ({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    minHeight: '37px'
  })
)

const FulfillmentLinkContainer = styled.a<
  StyledHTMLElement & { disabled: boolean },
  Required<Theme>
>(({ theme, disabled }) => ({
  display: 'flex',
  gap: '12px',
  fontSize: '14px',
  fontFamily: theme.fonts['heading']['family'],
  fontWeight: 600,
  textTransform: 'capitalize',
  color: '#fff',
  backgroundColor: theme.colors['primary'],
  alignItems: 'center',
  padding: '8.5px 14px',
  borderRadius: '12px',
  cursor: 'pointer',
  textDecoration: 'none',
  flexGrow: 1,
  opacity: disabled ? 0.25 : 1,
  pointerEvents: disabled ? 'none' : 'auto',

  // disable blue hightlight of user clicks
  WebkitTapHighlightColor: 'transparent',

  '& >svg': {
    minHeight: '20px',
    minWidth: '20px'
  },

  '&:hover': {
    opacity: 0.8
  },
  '&:active': {
    opacity: 0.6
  }
}))
const FulfillmentLinkContent = styled.span<StyledHTMLElement, Required<Theme>>(
  ({ theme }) => ({
    display: 'flex',
    flexWrap: 'wrap'
  })
)

const FulfillmentAvailabilitySlot = styled.span<
  StyledHTMLElement & { isUnavailable: boolean },
  Required<Theme>
>(({ theme, isUnavailable }) => ({
  fontSize: '14px',
  fontWeight: 600,
  color: '#2A2A2A',
  opacity: isUnavailable ? 0.25 : 1
}))

export default FulfillmentInfo
