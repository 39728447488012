import {
  DELIVERY_FULFILLMENT,
  Fulfillment,
  FulfillmentType,
  ORDER_AT_TABLE_FULFILLMENT
} from 'shop/types'
import { Fulfillment as CartFulfillment } from 'shop/types/cart'
import { getFulfillmentFlags } from 'shop/utils'

/** Takes default fulfillment info & current cart info and returns vars for use in DeliverySummary */
export const getFulfillmentDetails = (
  cartFulfillment: CartFulfillment | null,
  deliveryFulfillment: Fulfillment | null,
  pickupFulfillment: Fulfillment | null,
  defaultFulfillmentType: FulfillmentType
) => {
  const { isDelivery: cartIsDelivery, isOrderAtTable } = getFulfillmentFlags(
    cartFulfillment?.type
  )

  const isDelivery = cartFulfillment
    ? cartIsDelivery
    : defaultFulfillmentType === DELIVERY_FULFILLMENT

  const defaultFulfillment = cartFulfillment
    ? null
    : isDelivery
      ? deliveryFulfillment
      : pickupFulfillment

  const fulfillmentWindow = cartFulfillment
    ? cartFulfillment.window
    : defaultFulfillment?.fulfillmentWindow

  const orderType = cartFulfillment
    ? cartFulfillment.orderType
    : defaultFulfillment?.orderType

  const isOat = cartFulfillment
    ? isOrderAtTable
    : defaultFulfillmentType === ORDER_AT_TABLE_FULFILLMENT

  return { isDelivery, defaultFulfillment, fulfillmentWindow, orderType, isOat }
}
