import { createContext, useContext, useEffect, useState } from 'react'
import {
  consumerClientContext,
  getClientLinks,
  QUERY_GET_MERCHANT,
  QUERY_GET_PARTNER,
  useShopClient
} from 'shop/client'
import { ApolloClient } from '@apollo/client'
import { CartSession } from 'shop/client/cart'
import { Store, Partner } from 'shop/components/Landing'
import { Cart } from 'shop/components'
import config from 'shop/config'
import {
  CustomerDetails,
  FulfillmentType,
  OrderType,
  ShopConfig
} from 'shop/types'
import {
  initializeMerchantGA4,
  initializeTagManager,
  initializePixel,
  initializeSlerpGA4,
  initializeGoogleConversion
} from 'tracker'
import AllTrackers from 'tracker/AllTrackers'
import { expandEnrichedEventData } from 'tracker/utils'
import { useAccount } from '../useAccount'
import { useRwg } from '../useRwg'
import { mapStoreSettingsIntoObject } from './helpers'

/**
 *  The shop context interface
 */
export type ShopInterface = {
  config: ShopConfig
  cartSession: CartSession
  merchant: any
  setMerchant: React.Dispatch<any>
  partner: Partner | null
  setPartner: React.Dispatch<Partner | null>
  allStores: Store[]
  setAllStores: React.Dispatch<Store[]>
  customerId: string
  setCustomerId: React.Dispatch<any>
  isStoreLoading: boolean
  setIsStoreLoading: React.Dispatch<any>
  isProductsLoading: boolean
  setIsProductsLoading: React.Dispatch<any>
  useShopClient(): ApolloClient<object>
  customerApiKey: string
  setCustomerApiKey: React.Dispatch<any>
  customerDetails: CustomerDetails | null
  setCustomerDetails: React.Dispatch<CustomerDetails | null>
  currentStore: Store | undefined
  setCurrentStore: React.Dispatch<Store>
  refetchMerchantAndStores: () => void
  isPartnerLoading: boolean
  setIsPartnerLoading: React.Dispatch<boolean>
}

const initialState = {
  appContent: null,
  config,
  useShopClient,
  merchant: null,
  setMerchant: () => {},
  partner: null,
  setPartner: () => {},
  allStores: [],
  setAllStores: () => {},
  customerId: '',
  setCustomerId: () => {},
  cartSession: {
    isCartLoading: true,
    setIsCartLoading: () => {},
    config,
    cartId: '',
    cart: null,
    setCart: () => {}
  },
  store: null,
  setStore: () => {},
  isStoreLoading: false,
  setIsStoreLoading: () => {},
  isProductsLoading: false,
  setIsProductsLoading: () => {},
  customerApiKey: '',
  setCustomerApiKey: () => {},
  customerDetails: null,
  setCustomerDetails: () => {},
  currentStore: undefined,
  setCurrentStore: () => {},
  refetchMerchantAndStores: () => {},
  isPartnerLoading: true,
  setIsPartnerLoading: () => {}
}

export const SetupSlerpShop = () => {
  const client = useShopClient()
  const [cart, setCart] = useState<Cart | null>(null)
  const [isCartLoading, setIsCartLoading] = useState(true)
  const [merchant, setMerchant] = useState<any>() // No Type for the Hasura Merchant :'(
  const [partner, setPartner] = useState<Partner | null>(null)
  const [isPartnerLoading, setIsPartnerLoading] = useState<boolean>(true)
  const [allStores, setAllStores] = useState<Store[]>([])
  const [currentStore, setCurrentStore] = useState<Store>()
  const [customerId, setCustomerId] = useState(
    localStorage.getItem('customerId') || ''
  )
  const [customerApiKey, setCustomerApiKey] = useState(
    localStorage.getItem('customerApiKey') || ''
  )
  const [isStoreLoading, setIsStoreLoading] = useState(true)
  const [isProductsLoading, setIsProductsLoading] = useState(true)
  const [customerDetails, setCustomerDetails] =
    useState<CustomerDetails | null>(null)
  const [hasRecentlyFetchedMerchant, setHasRecentlyFetchedMerchant] =
    useState(false)

  const { fetchCustomerDetails } = useAccount()
  const { setRWGOnEnrichedEventData } = useRwg()

  useEffect(() => {
    if (cart) setIsCartLoading(false)

    return () => {
      setIsCartLoading(true)
    }
  }, [cart])

  /** Set the currentStore from the cart */
  useEffect(() => {
    if (cart?.store.slug && !!allStores.length) {
      if (cart.store.slug !== currentStore?.slug) {
        const matchedStore = allStores.find(
          (store) => store.slug === cart.store.slug
        )
        setCurrentStore(matchedStore)
      }
    }
    // not "currentStore?.slug" as a dependancy as we are setting it.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allStores, cart?.store.slug])

  /** Update Trackers with new Store data */
  useEffect(() => {
    if (!!currentStore?.id) {
      expandEnrichedEventData(AllTrackers.getInstance().slerpGA4Tracker, {
        store_id: currentStore.id,
        store_slug: currentStore.slug
      })
      expandEnrichedEventData(AllTrackers.getInstance().merchantGA4Tracker, {
        store_id: currentStore.id,
        store_slug: currentStore.slug
      })
    }
  }, [currentStore?.id, currentStore?.slug])

  /** Update Trackers with new Merchant data */
  useEffect(() => {
    if (!!merchant?.id) {
      expandEnrichedEventData(AllTrackers.getInstance().slerpGA4Tracker, {
        merchant_id: merchant.id,
        merchant_slug: merchant.slug
      })
      expandEnrichedEventData(AllTrackers.getInstance().merchantGA4Tracker, {
        merchant_id: merchant.id,
        merchant_slug: merchant.slug
      })
    }
  }, [merchant?.id, merchant?.slug])

  /** Update trackers with fulfillment type & order type */
  useEffect(() => {
    if (cart) {
      const orderType: OrderType = cart.is_preorder
        ? 'PREORDER'
        : cart.fulfillment_time_range === 'ASAP'
          ? 'ASAP'
          : 'SAMEDAY'

      const additionalEventData = {
        fulfillment_type:
          cart.fulfillment_type.toUpperCase() as FulfillmentType,
        order_type: orderType
      }

      expandEnrichedEventData(
        AllTrackers.getInstance().slerpGA4Tracker,
        additionalEventData
      )
      expandEnrichedEventData(
        AllTrackers.getInstance().merchantGA4Tracker,
        additionalEventData
      )
    }
  }, [cart?.fulfillment_type, cart?.is_preorder, cart?.fulfillment_time_range])

  useEffect(() => {
    setHasRecentlyFetchedMerchant(true)
    client
      .query({
        query: QUERY_GET_MERCHANT,
        variables: { slug: config.domain }
      })
      .then((result) => {
        const [merchant] = result?.data?.merchants
        let stores = result?.data?.stores

        if (!!stores.length) stores = mapStoreSettingsIntoObject(stores)

        // Initializing these analytics properties should only happen once
        initializeMerchantGA4(merchant, customerId)
        initializeTagManager(merchant)
        initializePixel(merchant)

        initializeGoogleConversion(merchant)
        initializeSlerpGA4(merchant, customerId)
        setMerchant(merchant)
        setAllStores(stores)
        setRWGOnEnrichedEventData() // has to be called once GA4 has been initialised

        setTimeout(() => {
          setHasRecentlyFetchedMerchant(false)
        }, 10000) // 10 secs
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setIsPartnerLoading(true)
    client
      .query({
        query: QUERY_GET_PARTNER,
        ...consumerClientContext
      })
      .then(({ data: { partner } }) => {
        setPartner(partner)
      })
      .finally(() => {
        setIsPartnerLoading(false)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  /** Update client with Merchant data */
  useEffect(() => {
    if (!!merchant?.id) {
      const setMerchantRequestHeader = (merchantId: string) => {
        const { domain, apiKey } = config
        const clientLinks = getClientLinks(domain, apiKey, merchantId)
        client.setLink(clientLinks)
      }
      // refresh client link with merchant.id
      setMerchantRequestHeader(merchant.id)
    }
  }, [merchant?.id])

  // on load of context, fetch customer details if "logged in"
  useEffect(() => {
    if (!customerId || !!customerDetails) return
    const fetchCustomer = async () => {
      const customer = await fetchCustomerDetails()
      setCustomerDetails(customer)
    }
    fetchCustomer()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerId, setCustomerDetails])

  const cartSession = {
    config,
    cart,
    setCart,
    cartId: '',
    isCartLoading,
    setIsCartLoading
  }

  const refetchMerchantAndStores = () => {
    if (hasRecentlyFetchedMerchant) return
    setHasRecentlyFetchedMerchant(true)
    client
      .query({
        query: QUERY_GET_MERCHANT,
        variables: { slug: config.domain }
      })
      .then((result) => {
        const [merchant] = result?.data?.merchants
        let stores = result?.data?.stores

        if (!!stores.length) stores = mapStoreSettingsIntoObject(stores)

        setMerchant(merchant)
        setAllStores(stores)
        setTimeout(() => {
          setHasRecentlyFetchedMerchant(false)
        }, 10000) // 10 secs
      })
  }

  return {
    config,
    useShopClient,
    cartSession,
    merchant,
    setMerchant,
    partner,
    setPartner,
    allStores,
    setAllStores,
    customerId,
    setCustomerId,
    isStoreLoading,
    setIsStoreLoading,
    isProductsLoading,
    setIsProductsLoading,
    customerApiKey,
    setCustomerApiKey,
    customerDetails,
    setCustomerDetails,
    currentStore,
    setCurrentStore,
    refetchMerchantAndStores,
    isPartnerLoading,
    setIsPartnerLoading
  }
}

export const ShopContext = createContext<ShopInterface>(initialState)

export const useShop = () => {
  return useContext(ShopContext)
}
