import { useEffect, useState, useMemo, useRef, useCallback } from 'react'
import AlertMessage from '../components/Cart/AlertMessage'
import Spinner from 'shop/components/Loader/Spinner'
import styled from '@emotion/styled'
import {
  Button,
  Heading,
  CustomerOptIn,
  CustomerDetailsForm,
  RecipientDetails,
  ShippingDetails,
  OrderNotes,
  CustomField,
  NavBarV2
} from 'shop/components'
import { CheckoutActions } from 'shop/components/Checkout/FormElements'
import {
  UpdateDateCartRecipientDetails,
  getPayNextStep
} from 'shop/components/Checkout/Network'
import {
  getPrefilledAddress,
  getFormDefaultValue,
  hasCustomField,
  updateContactNumCheckout,
  returnStoreBaseAddress,
  defaultCheckoutErrorMessage,
  getIsAddressFormOpen,
  findAndSetCartErrors,
  findAndSetDeliveryErrors,
  getCheckoutCtaValues,
  getCurrentDiscountWarningErrors,
  getInvalidItemIds,
  isDiscountErrorCode,
  DiscountErrorCode,
  isCartProductError,
  SHIPPING_INPUTS
} from 'shop/components/Checkout/utils'
import { strippedContactNumPrefix } from 'shop/components/Inputs/utils'
import {
  CheckoutForm,
  FORM_DEFAULTS,
  ADDRESS_DEFAULTS,
  FormFields
} from 'shop/components/Checkout/types'
import {
  useShop,
  useConsumerCart,
  useAppContent,
  useModal,
  useCheckout,
  useMediaQueries,
  useIntersectionObserver
} from 'shop/hooks'
import { useForm } from 'react-hook-form'
import { UseFormMethods } from 'react-hook-form'
import isEmpty from 'lodash/isEmpty'
import { usePageViewTracker } from 'tracker'
import { useHistory } from 'react-router-dom'
import TippingV2 from 'shop/components/Tipping/TippingV2'
import { useSessionStorage } from 'shop/hooks/useSessionStorage'
import { keysHaveValues, returnToShop } from 'shop/utils/common'
import { CartV2, FulfillmentDetails } from 'shop/components/CartV2'
import { Theme } from 'styled-system'
import { StyledHTMLElement } from 'shop/theme/types'
import {
  CartErrorMessage,
  CartMutationResponse,
  CartProductError,
  CartWarningMessage,
  ConsumerCart,
  DomesticAddress,
  ExtendedOrderItemV2,
  OrderItemV2,
  TipValues
} from 'shop/types/cart'
import {
  trackConsumerCart,
  trackDeniedDiscountCodeConsumerCart,
  trackMovActioned,
  trackRemoveDiscountCodeConsumerCart
} from 'shop/components/Checkout/tracking/helpers'
import DiscountErrorModal from 'shop/components/ErrorModals/DiscountErrorModal'
import { CHECKOUT_ORIGIN, FulfillmentType } from 'shop/types'
import ErrorModal from 'shop/components/Modal/ErrorModal'
import GiftWrapping from 'shop/components/CartV2/GiftWrapping/GiftWrapping'
import CartProductErrorModalV2 from 'shop/components/Modal/CartProductErrorModalV2'
import SaveAddressCheckbox from 'shop/components/Checkout/SaveAddressCheckbox'
import { LineBreak } from 'shop/components/Shop/commonStyles'
import { findErrorWarning, getInvalidItems } from 'shop/components/Cart/utils'
import { getFulfillmentFlags } from 'shop/utils'

// Determines whether error modals trigger checkout page form submission ("PAY") or not ('PAGE")
type ErrorModalType = 'PAGE' | 'PAY' | null

const Checkout = () => {
  const {
    useShopClient,
    customerDetails,
    currentStore,
    setCurrentStore,
    allStores
  } = useShop()
  const { isMobile } = useMediaQueries()
  const {
    cart: consumerCart,
    updateConsumerCart,
    updateFulfillmentConsumerCart,
    setCart,
    removeDiscountConsumerCart,
    cartLoading,
    errors: consumerCartErrors,
    warnings: consumerCartWarnings,
    validateCart,
    removeOrderItemsConsumerCart
  } = useConsumerCart()
  const {
    timeSlotValidation,
    discountErrorWarnings,
    giftWrapped,
    giftWrapMessage,
    setGiftWrapped,
    setGiftWrapMessage,
    updateCartGiftWrapping,
    rewardsRef,
    productError,
    isPaying,
    setIsPaying,
    isTippingOpen,
    setIsTippingOpen
  } = useCheckout()
  const { saveAddressInputId } = SHIPPING_INPUTS
  const client = useShopClient()
  const isLoggedIn = localStorage.getItem('customerId') !== null
  const [isDeliveryLoading, setIsDeliveryLoading] = useState(false)
  const [showProductErrorModal, setShowProductErrorModal] =
    useState<ErrorModalType>(null)
  const [invalidItems, setInvalidItems] = useState<
    (OrderItemV2 | ExtendedOrderItemV2)[]
  >([])
  const [showDiscountErrorModal, setShowDiscountErrorModal] =
    useState<ErrorModalType>(null)
  const [shouldValidateCart, setShouldValidateCart] = useState(false)
  const history = useHistory()

  const {
    getSessionStorageItem: getCheckoutFormSessionStorage,
    setSessionStorageItem: setCheckoutFormSessionStorage
  } = useSessionStorage<CheckoutForm>('temp-checkout-form-values')

  /** Pass cartLoading value to handleValidateCart to ensure correct useState value is sent */
  const handleValidateCart = useCallback(() => {
    validateCart(cartLoading)
  }, [cartLoading, validateCart])

  useEffect(() => {
    if (shouldValidateCart) {
      handleValidateCart()
      setShouldValidateCart(false)
    }
  }, [shouldValidateCart, handleValidateCart])

  useEffect(() => {
    const SECOND = 1000
    let pollCartVerificationInterval: NodeJS.Timeout
    let checkStartPollInterval: NodeJS.Timeout

    const currentSeconds = new Date().getSeconds()
    const timeUntilNextMinute = (60 - currentSeconds + 1) * SECOND

    const startPolling = () => {
      // Schedule the first validation at the start of the next minute
      pollCartVerificationInterval = setTimeout(() => {
        setShouldValidateCart(true)
        // Schedule subsequent polling every 30 seconds
        checkStartPollInterval = setInterval(() => {
          setShouldValidateCart(true)
        }, 30 * SECOND)
      }, timeUntilNextMinute)
    }

    // Start validateCart polling
    startPolling()

    return () => {
      if (pollCartVerificationInterval) {
        clearTimeout(pollCartVerificationInterval)
      }
      if (checkStartPollInterval) {
        clearInterval(checkStartPollInterval)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Values from session storage are used to prefill the form if no cart loaded
  const tempSavedCheckoutValues = getCheckoutFormSessionStorage()

  const defaultFormValues = tempSavedCheckoutValues
    ? tempSavedCheckoutValues
    : FORM_DEFAULTS

  const checkoutForm = useForm({
    mode: 'onBlur',
    defaultValues: defaultFormValues
  })

  // Track page view
  usePageViewTracker()

  const {
    getValues,
    register,
    setError: setFormError,
    clearErrors,
    errors,
    watch,
    reset
  }: UseFormMethods<FormFields> = checkoutForm
  const { merchantName } = useAppContent()
  const { merchant } = useShop()
  const { isModalOpen, loginModal, timeSlotModalV2 } = useModal()

  const [paymentError, setPaymentError] = useState<Error | null>(null)
  const [tippingValues, setTippingValues] = useState<TipValues[]>([])
  const [deliveryAddress, setDeliveryAddress] = useState<DomesticAddress>(
    getPrefilledAddress(consumerCart, tempSavedCheckoutValues, ADDRESS_DEFAULTS)
  )
  const [hasSeenRewards, setHasSeenRewards] = useState<boolean>(false)

  const fulfillmentType = consumerCart?.fulfillment?.type

  const [isDeliveryAddressFormOpen, setIsDeliveryAddressFormOpen] =
    useState<boolean>(false)

  const prevAddressRef = useRef<DomesticAddress | undefined>()
  const orderNotesEnabled = watch('orderNotesEnabled')
  const dropoffNotes = watch('dropoffNotes')

  // Check for subtotal discount - MOV
  const hasSubtotalDiscount = useMemo(() => {
    // FIXME: use consumerCart?.summary.subtotal.reduction instead
    const hasSubtotalReduction =
      consumerCart?.summary.subtotal.discounted !==
      consumerCart?.summary.subtotal.base
    const hasDiscount = !!consumerCart?.summary.discount
    return !!(hasDiscount && hasSubtotalReduction)
  }, [consumerCart?.summary.subtotal.discounted])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    if (fulfillmentType) {
      const initialIsDeliveryAddressFormOpen = getIsAddressFormOpen(
        fulfillmentType,
        deliveryAddress
      )
      setIsDeliveryAddressFormOpen(initialIsDeliveryAddressFormOpen)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fulfillmentType])

  /** Temp: Set currentstore from consumerCart as we no longer set old cart here
   * Should be moved to useShop eventually using consumerCart as dependency
   */
  useEffect(() => {
    const { store } = consumerCart || {}
    const { slug: storeSlug } = store || {}
    if (storeSlug && allStores.length) {
      if (storeSlug !== currentStore?.slug) {
        const matchedStore = allStores.find((store) => store.slug === storeSlug)
        if (matchedStore) {
          setCurrentStore(matchedStore)
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allStores, consumerCart])

  /** Hydrates form with saved cart/session values / check validity of delivery address */
  const initializeForm = (
    cart: ConsumerCart,
    fulfillmentType: FulfillmentType
  ) => {
    const isForSomeoneElse =
      !!cart.recipientDetails ||
      (!!tempSavedCheckoutValues?.recipientDetails &&
        keysHaveValues(
          Object.keys(FORM_DEFAULTS['recipientDetails']),
          tempSavedCheckoutValues.recipientDetails
        )) ||
      FORM_DEFAULTS['forSomeoneElse']

    const formValuesToSet = {
      ...FORM_DEFAULTS,
      deliveryAddress: deliveryAddress,
      saveAddress:
        tempSavedCheckoutValues?.saveAddress ?? (cart?.saveAddress || true),
      customerDetails: getFormDefaultValue('customerDetails', [
        cart,
        tempSavedCheckoutValues
      ]),
      recipientDetails: getFormDefaultValue('recipientDetails', [
        cart,
        tempSavedCheckoutValues
      ]),
      forSomeoneElse: isForSomeoneElse,
      orderNotes: getFormDefaultValue('orderNotes', [
        cart,
        tempSavedCheckoutValues
      ]),
      orderNotesEnabled: getFormDefaultValue('orderNotes', [
        cart,
        tempSavedCheckoutValues
      ]),
      dropoffNotes: getFormDefaultValue('dropoffNotes', [
        cart,
        tempSavedCheckoutValues
      ]),
      marketingOptIn:
        tempSavedCheckoutValues?.marketingOptIn ??
        (cart?.marketingOptIn || true),
      customFieldValue:
        cart.customField?.value ||
        tempSavedCheckoutValues?.customFieldValue ||
        FORM_DEFAULTS.customFieldValue
    }

    let { customerDetails, recipientDetails } = formValuesToSet

    customerDetails = {
      ...customerDetails,
      ...updateContactNumCheckout(
        customerDetails?.contactNumber,
        customerDetails?.contactNumPrefix
      )
    }
    if (recipientDetails) {
      recipientDetails = {
        ...recipientDetails,
        ...updateContactNumCheckout(
          recipientDetails?.contactNumber,
          recipientDetails?.contactNumPrefix
        )
      }
    }

    reset({ ...formValuesToSet, recipientDetails, customerDetails })

    setDeliveryAddress(deliveryAddress)
    prevAddressRef.current = { ...deliveryAddress }

    // On init, if it is a delivery fulfillment & delivery address form is not
    // open (has all required values to attempt BE validation)
    // call updateDeliveryAddress to validate prefilled address
    const initialIsDeliveryAddressFormOpen = getIsAddressFormOpen(
      fulfillmentType,
      deliveryAddress
    )

    if (isDelivery && !initialIsDeliveryAddressFormOpen) {
      updateDeliveryAddress(deliveryAddress)
    }
  }

  /** Initialise component */
  useEffect(() => {
    let isMounted = true

    const startLoad = async () => {
      if (!merchantName || !fulfillmentType) return
      if (!isMounted) return

      // If user tries to navigate to checkout page with no order items reroute user
      if (!consumerCart?.orderItems?.length) {
        returnToShop(currentStore, history)
        return
      }

      const storeName = consumerCart.store.name

      trackConsumerCart(consumerCart, merchantName, storeName)
      initializeForm(consumerCart, fulfillmentType)
    }

    startLoad()

    return () => {
      isMounted = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [merchantName, fulfillmentType])

  /** Get tip values first time cart is loaded */
  useEffect(() => {
    if (!consumerCart?.id) return
    if (!!consumerCart.tipValues?.length) {
      setTippingValues(consumerCart.tipValues)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [consumerCart?.id])

  /** Handles consumer delivery address & cart validations. Sets appropriate UI error messages */
  useEffect(() => {
    const hasValidationInfo =
      !!consumerCartErrors.length || !!consumerCartWarnings.length

    if (!hasValidationInfo) {
      clearErrors('address_api')
      clearErrors('checkout_cart')
      return
    }

    // If there is a discount error and a discount code is already applied, show discount error modal
    if (consumerCartErrors.length && consumerCart?.summary.discount?.code) {
      const discountErrorCode = findErrorWarning(
        consumerCartErrors,
        isDiscountErrorCode
      )?.message as DiscountErrorCode
      // Trigger discount errors.
      if (discountErrorCode) {
        // If showDiscountErrorModal is set to 'PAY' do not override
        !showDiscountErrorModal && setShowDiscountErrorModal('PAGE')
      }
    }

    if (consumerCartErrors.length) {
      const currentProductError = findErrorWarning(
        consumerCartErrors,
        isCartProductError
      ) as CartProductError

      if (currentProductError && consumerCart?.orderItems) {
        setInvalidItems(
          getInvalidItems(currentProductError, consumerCart.orderItems)
        )
        // If showProductErrorModal is set to 'PAY' do not override
        !showProductErrorModal && setShowProductErrorModal('PAGE')
      }
    }

    // Find and handle cart error code from cart errors
    findAndSetCartErrors(
      consumerCartErrors,
      consumerCartWarnings,
      hasSubtotalDiscount,
      setFormError,
      clearErrors
    )
    // Find and handle delivery error code from cart errors
    const deliveryErrorCode = findAndSetDeliveryErrors(
      consumerCartErrors,
      consumerCartWarnings,
      setFormError,
      clearErrors
    )
    if (deliveryErrorCode) setIsDeliveryAddressFormOpen(true)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [consumerCartWarnings, consumerCartErrors])

  const showOptIn: boolean = useMemo(() => {
    // guest checkout
    if (!isLoggedIn) return true
    // account and not yet opted in
    if (!customerDetails?.marketingOptIn) return true
    return false
  }, [isLoggedIn, customerDetails?.marketingOptIn])

  const updateDeliveryAddress = async (address: DomesticAddress) => {
    setIsDeliveryLoading(true)
    const deliveryAddressWithCountry = {
      ...address,
      country: 'United Kingdom'
    }
    return updateFulfillmentConsumerCart({
      deliveryAddress: deliveryAddressWithCountry
    })
      .then((cart: ConsumerCart | null) => {
        if (cart) {
          setCart(cart)
        }
      })
      .catch(() => {
        setFormError('address_api', {
          type: 'manual',
          message: defaultCheckoutErrorMessage
        })
        setIsDeliveryAddressFormOpen(true)
      })
      .finally(() => {
        setIsDeliveryLoading(false)
      })
  }

  /** Update fulfillment type via discount error modal */
  const updateFulfillmentType = (fulfillmentType: FulfillmentType) => {
    setIsDeliveryLoading(true)
    return updateFulfillmentConsumerCart({
      fulfillmentType: fulfillmentType
    })
      .then((cart: ConsumerCart | null) => {
        if (cart) {
          setCart(cart)
        }
      })
      .catch(() => {
        setFormError('fulfillment_api', {
          type: 'manual',
          message: defaultCheckoutErrorMessage
        })
        setIsDeliveryAddressFormOpen(true)
      })
  }

  // If rewards have come into viewport, set hasSeenRewards to true
  const handleRewardsIntersection = (entries: IntersectionObserverEntry[]) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        setHasSeenRewards(true)
      }
    })
  }

  // Create intersectionObs to check if rewards have been scrolled 50% up the viewport
  const rewardsObserver = useIntersectionObserver(
    handleRewardsIntersection,
    rewardsRef.current,
    {
      root: null,
      threshold: 0,
      rootMargin: '0px 0px -50% 0px'
    }
  )

  useEffect(() => {
    // Stop checking if rewards are in viewport once user has seen them or clicked CTA
    if (hasSeenRewards && rewardsObserver) {
      rewardsObserver.disconnect()
    }
  }, [hasSeenRewards, rewardsObserver])

  /** Removes order items when there is a product error */
  const handleRemoveOrderItemsFromCart = useCallback(
    async (submitForm: boolean) => {
      const invalidItemIds = getInvalidItemIds(invalidItems)

      removeOrderItemsConsumerCart({ ids: invalidItemIds }).then(
        (res: CartMutationResponse | null) => {
          // If cart exists but has no order items after removal, redirect user back to shop
          if (res?.cart && !res?.cart?.orderItems.length) {
            setShowProductErrorModal(null)
            returnToShop(currentStore, history)
            return
          }
        }
      )

      setShowProductErrorModal(null)

      if (submitForm) {
        handleSubmitForm()
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [productError, invalidItems]
  )

  /** Send boolean to BE confirming user has agreed price change */
  const handleProceedWithPriceChange = useCallback((submitForm: boolean) => {
    updateFulfillmentConsumerCart({ proceedWithNewPrices: true })
      .then(() => {
        if (submitForm) {
          handleSubmitForm()
        }
        return
      })
      .finally(() => setShowProductErrorModal(null))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!consumerCart?.summary || !fulfillmentType) return <></>

  const {
    orderItems,
    fulfillment,
    summary,
    store,
    loyaltyCards,
    orderNotes,
    deliveryAddress: cartDeliveryAddress,
    additionalItems
  } = consumerCart
  const { slug: storeSlug } = store

  const { isOrderAtTable, isDelivery } = getFulfillmentFlags(fulfillmentType)

  const { checkoutPayCtaActive, checkoutCtaText } = getCheckoutCtaValues(
    loyaltyCards,
    isMobile,
    hasSeenRewards
  )

  const {
    gift_wrap_enabled,
    gift_wrap_placeholder,
    gift_wrap_price,
    gift_wrap_text
  } = merchant
  const showGiftWrap: boolean = !!gift_wrap_enabled && !!gift_wrap_price

  const storeAddress = returnStoreBaseAddress(store.address)

  const showCustomField = () => {
    if (!merchant || !fulfillmentType) return false

    const {
      order_custom_field,
      order_custom_field_name,
      order_custom_field_apply_for
    } = merchant
    const type = fulfillmentType.toLowerCase()
    return hasCustomField(
      { fulfillment_type: type, id: consumerCart.id },
      {
        enabled: order_custom_field,
        name: order_custom_field_name,
        fulfillment_types: order_custom_field_apply_for
      }
    )
  }

  const setTipOnConsumerCart = (tipAmount?: string) => {
    if (!!tipAmount) sessionStorage.setItem('hasSelectedTip', 'true')
    if (isTippingOpen) setIsTippingOpen(false)
    updateConsumerCart({
      tipValue: Number(tipAmount)
    }).then((cartResponse: CartMutationResponse | null) => {
      const { cart } = cartResponse || {}
      if (cart) {
        setCart(cart)
        return
      }
      throw new Error('Update cart failed')
    })
  }

  /** Updates cart when user clicks pay & navigates to payment page */
  const checkout = (tipAmount?: string) => {
    if (!!tipAmount) sessionStorage.setItem('hasSelectedTip', 'true')
    if (isTippingOpen) setIsTippingOpen(false)

    const {
      customerDetails: {
        contactNumPrefix,
        contactNumber,
        ...otherCustomerDetails
      },
      recipientDetails,
      orderNotes,
      dropoffNotes,
      marketingOptIn,
      saveAddress,
      customFieldValue
    } = checkoutForm.getValues()

    setIsPaying(true)

    const customerDetails = {
      ...otherCustomerDetails,
      contactNumber: `${strippedContactNumPrefix(
        contactNumPrefix
      )}${contactNumber}`
    }

    let amendedRecipientDetails: UpdateDateCartRecipientDetails =
      recipientDetails
    if (recipientDetails?.firstName) {
      const {
        contactNumPrefix: rec_prefix,
        contactNumber: rec_num,
        ...otherRecipientDetails
      } = recipientDetails

      amendedRecipientDetails = {
        ...otherRecipientDetails,
        contactNumber: `${strippedContactNumPrefix(rec_prefix)}${rec_num}`
      }
    }

    // Ensure customer_details.email is in lowercase
    if (customerDetails?.email) {
      customerDetails.email = customerDetails.email.toLowerCase()
    }

    return updateConsumerCart(
      {
        customFieldValue,
        customerDetails,
        orderNotes,
        recipientDetails: amendedRecipientDetails || recipientDetails,
        dropoffNotes,
        marketingOptIn,
        saveAddress,
        tipValue: !!tipAmount ? Number(tipAmount) : undefined
      },
      { skipLoading: true }
    )
      .then((cartResponse: CartMutationResponse | null) => {
        if (cartResponse) {
          const { cart, warnings, errors } = cartResponse

          // Validation handling
          if (errors.length || warnings.length) {
            // Get most recent discount errors/warnings
            const currentDiscountErrorWarnings =
              getCurrentDiscountWarningErrors(errors, warnings)

            const currentProductError = findErrorWarning(
              errors,
              isCartProductError
            ) as CartProductError

            // Trigger discount errors/warnings
            if (currentDiscountErrorWarnings.length) {
              setShowDiscountErrorModal('PAY')
              setIsPaying(false)
              return
            }

            // If there are product errors, show product error modal
            if (currentProductError) {
              setShowProductErrorModal('PAY')
              setIsPaying(false)
              return
            }

            // Block users with generic error if there are errors or warnings that we are not handling
            setPaymentError(
              new Error(
                'Unable to process payments at this time. Please try again later.'
              )
            )
            setIsPaying(false)
            return
          }

          if (cart) {
            setCart(cart)
            return getPayNextStep(client, consumerCart.id)
          }
        }
        throw new Error('Update cart failed')
      })
      .then(async (result) => {
        if (result) {
          if (result.data.payForCart.nextStep === 'skip') {
            const transactionId = result.data.payForCart.transactionId
            return history.push(`/track/${transactionId}`)
          }

          return history.push('/pay')
        }
      })
      .catch((error) => {
        if (error.graphQLErrors && error.graphQLErrors.length > 0) {
          switch (error.graphQLErrors[0].code) {
            case 'STRIPE_AMOUNT_TOO_SMALL':
              return setPaymentError(
                new Error('Please ensure your total is above £0.30')
              )
            default:
              return setPaymentError(
                new Error(
                  'Unable to process payments at this time. Please try again later.'
                )
              )
          }
        }

        setPaymentError(
          new Error(
            'Unable to process payments at this time. Please try again later.'
          )
        )
        return setIsPaying(false)
      })
  }

  const hasTippingValues = !!tippingValues?.length
  const hasUserSelectedTipBefore =
    !!sessionStorage.getItem('hasSelectedTip') ||
    Number(consumerCart.summary.tip) > 0
  const showTippingModal = isTippingOpen && hasTippingValues

  const setCheckoutValuesOnBlur = () => {
    setCheckoutFormSessionStorage(getValues())
  }

  const handleBlur = () => {
    setDeliveryAddress(getValues().deliveryAddress)
  }

  const handleEnterKeyPress = (e: React.KeyboardEvent) => {
    const focusedInput: HTMLInputElement | null =
      document.querySelector('input:focus')
    if (e.key === 'Enter' && focusedInput) {
      focusedInput.blur()
      e.preventDefault()
    }
  }

  const scrollToRewards = () => {
    if (rewardsRef.current) {
      const topPosition =
        rewardsRef.current.getBoundingClientRect().top + window.scrollY - 50
      window.scrollTo({ top: topPosition, behavior: 'smooth' })
    }
  }

  /** Scrolls to rewards area if mobile user & has rewards, else normal payment flow */
  const handleSubmitForm = checkoutForm.handleSubmit(() => {
    if (checkoutPayCtaActive) {
      if (hasTippingValues) {
        if (!hasUserSelectedTipBefore) {
          setIsTippingOpen(true)
          return
        }
        checkout(
          consumerCart.summary.tip ? consumerCart.summary.tip : undefined
        )
      } else {
        checkout()
      }
    } else {
      scrollToRewards()
    }
  })

  const fulfillmentDetailsOnChangeClick = () =>
    setIsDeliveryAddressFormOpen(true)

  const handleRemoveDiscountViaModal = (submitForm: boolean) => {
    const discountCode = summary.discount?.code as string
    removeDiscountConsumerCart()
      .then(() => {
        trackRemoveDiscountCodeConsumerCart(discountCode)
        // reset discount error modal state to default
        setShowDiscountErrorModal(null)
        // If user was trying to pay, manually retrigger pay button flow after discount removal
        if (submitForm) {
          handleSubmitForm()
        }
      })
      .catch(() => {
        trackDeniedDiscountCodeConsumerCart(discountCode)
      })
  }

  return (
    <>
      {showProductErrorModal && productError && (
        <CartProductErrorModalV2
          loading={cartLoading}
          origin={CHECKOUT_ORIGIN}
          productErrorCode={productError.message}
          invalidItems={invalidItems}
          storeName={consumerCart?.store.name}
          onContinue={() =>
            productError.message === CartErrorMessage.PRODUCT_PRICES_CHANGED
              ? handleProceedWithPriceChange(showProductErrorModal === 'PAY')
              : handleRemoveOrderItemsFromCart(showProductErrorModal === 'PAY')
          }
        />
      )}
      {isModalOpen('login') && loginModal({ merchantId: merchant.id })}
      {isModalOpen('timeslotV2') && timeSlotModalV2(timeSlotValidation)}
      {!!discountErrorWarnings.length && showDiscountErrorModal && (
        <DiscountErrorModal
          errorCode={discountErrorWarnings[0].message}
          values={
            discountErrorWarnings[0].message ===
            CartWarningMessage.DISCOUNT_MINIMUM_VALUE_NOT_MET
              ? {
                  minimumOrderValue: discountErrorWarnings[0].minimumValue
                }
              : {}
          }
          origin={'checkout'}
          checkoutRemoveDiscount={() =>
            handleRemoveDiscountViaModal(showDiscountErrorModal === 'PAY')
          }
          updateFulfillmentType={updateFulfillmentType}
          storeSlug={storeSlug}
        />
      )}
      {!!errors?.['checkout_cart'] && (
        <ErrorModal
          errorCode='MOV_ERROR'
          errorMessages={[errors['checkout_cart'].message]}
          errorTitle='Minimum value not met'
          primaryButton={{
            onClick: () => {
              trackMovActioned()
              returnToShop(currentStore, history)
            },
            message: 'Add more items'
          }}
          secondaryButton={
            hasSubtotalDiscount
              ? {
                  onClick: () => handleRemoveDiscountViaModal(false),
                  message: 'Remove discount code'
                }
              : undefined
          }
        />
      )}
      {showTippingModal && (
        <TippingV2
          tippingValues={tippingValues}
          handleSubmitTip={
            hasUserSelectedTipBefore ? setTipOnConsumerCart : checkout
          }
          currentTipValue={consumerCart.summary.tip}
        />
      )}
      <Container>
        <NavBarV2 page='checkout' storeSlug={store.slug} />
        <ContentWrapper>
          <form
            onBlur={setCheckoutValuesOnBlur}
            onSubmit={handleSubmitForm}
            onKeyPress={handleEnterKeyPress}
          >
            {paymentError && (
              <AlertMessage type='error' heading={paymentError.message} />
            )}
            <FormSection>
              <Heading as='h2' margin='0 0 32px'>
                Personal Details
              </Heading>
              <CustomerDetailsForm
                formHandle={checkoutForm}
                onBlur={handleBlur}
              />
            </FormSection>
            {showGiftWrap && (
              <FormSection>
                <GiftWrapping
                  giftWrapped={giftWrapped}
                  giftWrapMessage={giftWrapMessage}
                  setGiftWrapped={setGiftWrapped}
                  setGiftWrapMessage={setGiftWrapMessage}
                  updateCartGiftWrapping={updateCartGiftWrapping}
                  giftWrapPrice={gift_wrap_price}
                  giftWrapPlaceholder={gift_wrap_placeholder}
                  giftWrapText={gift_wrap_text}
                />
              </FormSection>
            )}
            {isDelivery && (
              <>
                <FormSection margin='24px 0 32px'>
                  <RecipientDetails formHandle={checkoutForm} />
                </FormSection>
              </>
            )}
            {showCustomField() && (
              <FormSection>
                <CustomField
                  fieldName={merchant?.order_custom_field_name}
                  required={merchant?.order_custom_field_mandatory}
                  placeholder={merchant?.order_custom_field_placeholder || ''}
                  formHandle={checkoutForm}
                  error={errors.customFieldValue}
                />
              </FormSection>
            )}
            <LineBreak />
            <FormSection>
              <FulfillmentDetailsContainer
                hasDropoffNotes={
                  isDelivery && !isDeliveryAddressFormOpen && !!dropoffNotes
                }
              >
                <FulfillmentDetails
                  fulfillment={fulfillment}
                  storeName={store.name}
                  merchantName={merchantName}
                  deliveryAddress={cartDeliveryAddress}
                  storeAddress={storeAddress}
                  dropoffNotes={dropoffNotes}
                  {...(isDelivery && {
                    onChangeButtonClick: fulfillmentDetailsOnChangeClick
                  })}
                  hideAddressSection={isDeliveryAddressFormOpen}
                />
              </FulfillmentDetailsContainer>
              {isDelivery && !isDeliveryAddressFormOpen && (
                <SaveAddressCheckbox
                  formHandle={checkoutForm}
                  inputId={saveAddressInputId}
                  addressFormOpen={isDeliveryAddressFormOpen}
                  address={deliveryAddress}
                ></SaveAddressCheckbox>
              )}
              <LineBreak />
              {isDelivery && isDeliveryAddressFormOpen && (
                <>
                  <ShippingDetails
                    onBlur={handleBlur}
                    formHandle={checkoutForm}
                    displayedAddress={deliveryAddress}
                    deliveryNotePlaceholder={
                      merchant?.delivery_note_placeholder
                    }
                    setIsDeliveryLoading={setIsDeliveryLoading}
                    updateCart={updateDeliveryAddress}
                    prevAddress={prevAddressRef}
                  />
                  <LineBreak />
                </>
              )}
            </FormSection>
            {!isOrderAtTable && (
              <FormSection>
                <OrderNotes
                  formHandle={checkoutForm}
                  enabled={orderNotesEnabled}
                  placeholder={merchant?.order_note_placeholder}
                  error={errors.orderNotes}
                />
              </FormSection>
            )}
            <FormSection>
              <CustomerOptIn {...{ register, isVisible: showOptIn }} />
            </FormSection>
            <CheckoutActions>
              <Button
                type='submit'
                disabled={!isEmpty(errors) || isPaying || isDeliveryLoading}
                name='pay_via'
                value='standard'
              >
                {isPaying ? <Spinner /> : checkoutCtaText}
              </Button>
            </CheckoutActions>
          </form>
          <div>
            <CartV2
              type={'OrderSummary'}
              orderItems={orderItems}
              summary={summary}
              orderNotes={orderNotes}
              loyaltyCards={loyaltyCards}
              fulfillmentType={fulfillmentType}
              additionalItems={additionalItems}
              isEditable={true}
              isCartLoading={cartLoading}
            />
          </div>
        </ContentWrapper>
      </Container>
    </>
  )
}

const FormSection = styled.div<
  StyledHTMLElement & { margin?: string },
  Required<Theme>
>(({ theme, margin }) => ({
  margin: margin || '0 0 24px 0',
  padding: '0 16px',
  [theme.mediaQueries.viewport6]: {
    padding: '0'
  },

  '>h2': {
    fontSize: '20px',
    [theme.mediaQueries.viewport6]: {
      fontWeight: 'bold',
      fontSize: '24px'
    }
  }
}))

const Container = styled.div(() => ({
  minHeight: '100vh',
  backgroundColor: '#F5F5F5',
  position: 'relative'
}))

const ContentWrapper = styled.div<StyledHTMLElement, Required<Theme>>(
  ({ theme }) => ({
    minHeight: 'min-content',
    position: 'relative',
    margin: '0 auto',
    width: '100%',
    maxWidth: '1470px',
    height: '100%',
    display: 'grid',
    gap: '12px',
    paddingBottom: '90px',
    flexDirection: 'column-reverse',
    [theme.mediaQueries.viewport4]: {
      padding: '40px',
      gap: '24px'
    },
    [theme.mediaQueries.viewport8]: {
      gridTemplateColumns: 'minmax(0, 1fr) minmax(0, 1fr)',
      padding: '40px 75px 90px',
      gap: '40px',
      justifyContent: 'center'
    },
    '& > form': {
      backgroundColor: 'white',
      height: 'min-content',
      padding: '24px 4px',
      [theme.mediaQueries.viewport4]: {
        borderRadius: '12px',
        padding: '32px 32px 24px'
      },
      [theme.mediaQueries.viewport8]: {
        position: 'sticky',
        top: '104px'
      }
    },
    '& > div': {
      height: '100%',
      backgroundColor: 'white',
      padding: '24px 16px',
      minWidth: 0,
      [theme.mediaQueries.viewport4]: {
        borderRadius: '12px',
        padding: '32px 32px 12px'
      },
      [theme.mediaQueries.viewport8]: {
        position: 'sticky',
        top: '104px',
        height: 'fit-content'
      }
    }
  })
)

const FulfillmentDetailsContainer = styled.div<
  StyledHTMLElement & { hasDropoffNotes: boolean }
>(({ hasDropoffNotes }) => ({
  margin: hasDropoffNotes ? '28px 0' : '28px 0 0'
}))

export default Checkout
