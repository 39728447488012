import styled from '@emotion/styled'
import { useLayoutEffect, useRef, useState } from 'react'

type Props = {
  children: React.ReactNode
  isExpanded: boolean
  collapsedHeight: number | 'content'
  id?: string
  transition?: string
}

const ExpandableDiv = ({
  children,
  collapsedHeight,
  isExpanded,
  id,
  transition
}: Props) => {
  const contentRef = useRef<HTMLDivElement>(null)
  const [renderedHeight, setRenderedHeight] = useState<number>(0)

  useLayoutEffect(() => {
    const contentElHeight = contentRef.current?.scrollHeight
    if (contentElHeight) {
      if (isExpanded) return setRenderedHeight(contentElHeight)
      if (collapsedHeight === 'content')
        return setRenderedHeight(contentElHeight)
    }
    if (typeof collapsedHeight === 'number')
      return setRenderedHeight(collapsedHeight)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isExpanded]) // only trigger on when the div expands/collapses.

  return (
    <ExpandableDivContainer id={id} transition={transition}>
      <ContentContainer height={renderedHeight} transition={transition}>
        <div ref={contentRef}>{children}</div>
      </ContentContainer>
    </ExpandableDivContainer>
  )
}

const ExpandableDivContainer = styled.div<{
  transition?: string
}>(({ transition = 'height ease 500ms' }) => ({
  overflow: 'hidden',
  transition: transition
}))

const ContentContainer = styled.div<{
  height: number
  transition?: string
}>(({ height, transition = 'height ease 500ms' }) => ({
  overflow: 'hidden',
  height: height,
  transition: transition
}))

export default ExpandableDiv
