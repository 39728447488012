import { Helmet } from 'react-helmet'
import { useSlerpMerchant } from 'shop/hooks'
import { useEffect, useState } from 'react'
import { useRouteMatch } from 'react-router-dom'

interface Props {
  seoDescription?: string | null
  type: 'merchant' | 'store' | 'product'
}

export const MetaDescription = ({ seoDescription, type }: Props) => {
  const { isSeoEnabled } = useSlerpMerchant()

  const [modalMode, setModalMode] = useState(false)

  const metaId = 'meta-description'
  const productPath = useRouteMatch('/store/:store/:product')

  useEffect(() => {
    if (productPath) {
      setModalMode(true)
    } else {
      setModalMode(false)
    }
  }, [productPath])

  if (!isSeoEnabled || !seoDescription || (modalMode && type !== 'product')) {
    return <></>
  }

  return (
    <Helmet>
      <meta name='description' id={metaId} content={seoDescription}></meta>
    </Helmet>
  )
}
