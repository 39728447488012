import styled from '@emotion/styled'
import { useRouteMatch } from 'react-router-dom'
import { useAppContent, useConsumerCart, useMerchantStore } from 'shop/hooks'
import Theme, { StyledHTMLElement } from 'shop/theme/types'
import { getFulfillmentFlags } from 'shop/utils'
import useReactRouter from 'use-react-router'
import { humaniseParam } from './utils'

export const ShopBreadcrumbs = () => {
  const { storeName } = useMerchantStore()
  const { merchantName } = useAppContent()
  const { cart } = useConsumerCart()
  const storeMatch = useRouteMatch('/store/:store')
  const productMatch = useRouteMatch('/store/:store/:product')
  const { location } = useReactRouter()
  const { state } = location

  const { isOrderAtTable } = getFulfillmentFlags(cart?.fulfillment.type)

  const landingPageLink = isOrderAtTable
    ? `/order/table/${cart?.store.slug}`
    : '/order'

  if (!merchantName || !storeName) return <></>

  const productName =
    state?.product?.name || humaniseParam(productMatch?.params?.product)
  const isProductPage = storeMatch && productName

  return (
    <ShopBreadcrumbsContainer
      vocab='https://schema.org/'
      typeof='BreadcrumbList'
    >
      <li property='itemListElement' typeof='ListItem'>
        <BreadcrumbA property='item' typeof='WebPage' href={landingPageLink}>
          <span property='name'> {merchantName} </span>
        </BreadcrumbA>
        <meta property='position' content='1'></meta>
      </li>

      <BreadcrumbSpan> / </BreadcrumbSpan>

      <li property='itemListElement' typeof='ListItem'>
        {isProductPage ? (
          <BreadcrumbA
            property='item'
            typeof='WebPage'
            href={`/order${storeMatch.url}`}
          >
            <span property='name'> {storeName} </span>
          </BreadcrumbA>
        ) : (
          <BreadcrumbSpan property='name'> {storeName} </BreadcrumbSpan>
        )}
        <meta property='position' content='2'></meta>
      </li>

      {isProductPage && (
        <>
          <BreadcrumbSpan> / </BreadcrumbSpan>
          <li property='itemListElement' typeof='ListItem'>
            <BreadcrumbSpan property='name'> {productName} </BreadcrumbSpan>
            <meta property='position' content='3'></meta>
          </li>
        </>
      )}
    </ShopBreadcrumbsContainer>
  )
}

const Grey = { color: '#8C8C8C' }

const BreadcrumbA = styled.a(() => ({
  ...Grey,
  textDecoration: 'none'
}))

const BreadcrumbSpan = styled.span(() => ({
  ...Grey
}))

const ShopBreadcrumbsContainer = styled.ol<StyledHTMLElement, Required<Theme>>(
  ({ theme }) => ({
    margin: 0,
    marginTop: '24px',
    fontSize: '12px',
    padding: '0 16px',
    listStyleType: 'none',
    display: 'flex',
    gap: '5px',
    [theme.mediaQueries.viewport7]: {
      padding: '0'
    }
  })
)
