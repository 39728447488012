import gql from 'graphql-tag'

const merchantSettingPaths = `
  closed_store_message: setting(path: "closed_store_message")
  delivery_note_placeholder: setting(path: "delivery_note_placeholder")
  gift_wrap_enabled: setting(path: "gift_wrap_enabled")
  gift_wrap_text: setting(path: "gift_wrap_text")
  gift_wrap_price: setting(path: "gift_wrap_price")
  gift_wrap_placeholder: setting(path: "gift_wrap_placeholder")
  order_custom_field: setting(path: "order_custom_field")
  order_custom_field_mandatory: setting(
    path: "order_custom_field_mandatory"
  )
  order_custom_field_apply_for: setting(
    path: "order_custom_field_apply_for"
  )
  order_custom_field_name: setting(path: "order_custom_field_name")
  order_custom_field_placeholder: setting(
    path: "order_custom_field_placeholder"
  )
  order_note_placeholder: setting(path: "order_note_placeholder")
  tag_manager_id: setting(path: "tag_manager_id")
  google_analytics_4_id: setting(path: "google_analytics_4_id")
  pixel_id: setting(path: "pixel_id")
  pickup_address_enabled: setting(path: "pickup_address_enabled")
  google_ads_id: setting(path: "google_ads_id")
  google_conversion_id: setting(path: "google_conversion_id")
`

const storeSettingsPaths = `
  delivery_radius: settings(path: "delivery_radius")
  scheduled_delivery: settings(path: "scheduled_delivery")
  scheduled_pickup: settings(path: "scheduled_pickup")
  same_day_pickup: settings(path: "same_day_pickup")
  same_day_delivery: settings(path: "same_day_delivery")
  
  table_enabled: settings(path: "table_enabled")
  table_welcome_message: settings(path: "table_welcome_message")
  table_count: settings(path: "table_count")
  table_allow_counter_pickup: settings(path: "table_allow_counter_pickup")
  table_counter_pickup_message: settings(path: "table_counter_pickup_message")
  table_choices: settings(path: "table_choices")
  
  asap_only: settings(path: "asap_only")
  asap_enabled: settings(path: "asap_enabled")

  delivery_interval: settings(path: "delivery_interval")
  pickup_interval: settings(path: "pickup_interval")

  busy_preparation_time: settings(path: "busy_preparation_time")
  moderate_preparation_time: settings(path: "moderate_preparation_time")
  quiet_preparation_time: settings(path: "quiet_preparation_time")
  prep_mode: settings(path: "prep_mode")
  
  delivery_area: settings(path: "delivery_area")
  busy_delivery_area: settings(path: "busy_delivery_area")
  busy_delivery_area_enabled: settings(path: "busy_delivery_area_enabled")
  `

export const QUERY_GET_MERCHANT = gql`
  query getMerchantBySlug($slug: String!) {
    merchants(where: { slug: { _eq: $slug } }) {
      id
      name
      slug
      currency
      for_testing
      ${merchantSettingPaths}
    }
    stores(
      where: {
        archived_at: { _is_null: true }
        merchant: { slug: { _eq: $slug } }
      }
    ) {
      id
      name
      slug
      is_open
      address {
        line_1
        line_2
        city
        zip
        geom
      }
      cutoffs
      ${storeSettingsPaths}
      split_hours
      pre_order_settings {
        cutoffs
        delivery_enabled
        delivery_interval
        delivery_radius
        delivery_times
        pre_order_day_allowance
        pickup_times
        pickup_schedule
        pickup_interval
        pickup_enabled
        special_cutoffs_map
        delivery_area_setting
      }
    }
  }
`

export const QUERY_GET_MERCHANT_STORE = gql`
  query getMerchantAndStoreBySlug($merchant: String!, $slug: String!) {
    merchants(where: { slug: { _eq: $merchant } }) {
      id
      name
      slug
      closed_store_message: setting(path: "closed_store_message")
      order_note_placeholder: setting(path: "order_note_placeholder")
      delivery_note_placeholder: setting(path: "delivery_note_placeholder")
      gift_wrap_enabled: setting(path: "gift_wrap_enabled")
      gift_wrap_text: setting(path: "gift_wrap_text")
      gift_wrap_price: setting(path: "gift_wrap_price")
      gift_wrap_placeholder: setting(path: "gift_wrap_placeholder")
      order_custom_field: setting(path: "order_custom_field")
      order_custom_field_mandatory: setting(
        path: "order_custom_field_mandatory"
      )
      order_custom_field_apply_for: setting(
        path: "order_custom_field_apply_for"
      )
      order_custom_field_name: setting(path: "order_custom_field_name")
      order_custom_field_placeholder: setting(
        path: "order_custom_field_placeholder"
      )
      tag_manager_id: setting(path: "tag_manager_id")
      pixel_id: setting(path: "pixel_id")
      stores(where: { slug: { _eq: $slug }, archived_at: { _is_null: true } }) {
        id
        name
        slug
        is_open
        address {
          line_1
          line_2
          city
          zip
          geom
        }
        cutoffs
        ${storeSettingsPaths}
        split_hours
      }
    }
  }
`

export const QUERY_GET_PARTNER = gql`
  query Partner {
    partner {
      content {
        welcome {
          header
          message
        }
        images {
          banner {
            original
          }
          logos {
            alternative {
              original
            }
            primary {
              original
            }
          }
          splash {
            original
          }
        }
        promotionalBanner
      }
      name
      seo {
        description
        enabled
        tagline
      }
      stores {
        id
        address {
          city
          lineOne
          lineTwo
          zip
        }
        description
        name
        openingHours {
          dayOfWeek
          shifts {
            startTime
            endTime
          }
        }
        orderFulfillmentTypes {
          fulfillmentType
          orderType
          earliestTimeslot {
            from
            to
          }
          status
        }
        slug
      }
    }
  }
`
