import { useState, useEffect, useRef } from 'react'
import styled from '@emotion/styled'
import { Button } from '../Controls'
import CartItem from './CartItem'
import AlertMessage from './AlertMessage'
import SideModal from '../Modal/SideModal'
import { useCart, useModal, useShop, useConsumerCart } from 'shop/hooks'
import SpinnerModal from 'shop/components/Loader/SpinnerModal'
import { Cart } from '../Landing'
import { TrackableEvent } from 'tracker'
import { trackUserActionsFBPixel, trackUserActionsGA4 } from 'tracker'
import Heading from '../Heading'
import useRouter from 'use-react-router'
import { BsCart2 } from 'react-icons/bs'
import {
  createEcommEventDataFromConsumerCart,
  merchantGA4EcommTrackViewCart,
  slerpGA4EcommTrackViewCart
} from 'tracker/GA/ecommerce'
import {
  CartErrorMessage,
  ExtendedOrderItemV2,
  OrderItemV2
} from 'shop/types/cart'
import {
  checkMinimumOrderValue,
  formatMoney,
  getAlcoholicItems,
  getCurrentProductErrors,
  getInvalidItems
} from './utils'
import { isEqual } from 'lodash'

interface Props {
  closeCartModal?: () => void
}

const CartModal = ({ closeCartModal }: Props) => {
  const { closeModal } = useModal()
  const { history } = useRouter()
  const { cartSession, partner } = useShop()
  const { loadCart } = useCart()
  const { cart: consumerCart, validateCart, errors } = useConsumerCart()
  const [invalidOrderItems, setInvalidOrderItems] = useState<OrderItemV2[]>([])
  const [unavailableOrderItems, setUnavailableOrderItems] = useState<
    OrderItemV2[]
  >([])
  const [validOrderItems, setValidOrderItems] = useState<OrderItemV2[]>([])
  const [alcoholicProducts, setAlcoholicProducts] = useState<OrderItemV2[]>([])
  const [loadingMessage, setLoadingMessage] = useState('')
  const [shouldValidateCart, setShouldValidateCart] = useState(false)

  const [boxShadow, setBoxShadow] = useState(false)
  const itemsContainerRef = useRef<HTMLDivElement>(null)
  const buttonRef = useRef<HTMLDivElement>(null)

  const cartSubtotal = consumerCart?.summary.subtotal.base
  const orderItems = consumerCart?.orderItems
  const minimumOrderValue = consumerCart?.minimumOrderValue

  // Used to compare current orderItems from previous orderItems to check if cart has been updated
  const orderItemsRef = useRef<OrderItemV2[]>([])

  const isMounted = useRef(true)

  cartSession.onCartUpdate = () => {
    loadCartItems()
  }

  /** Runs once on mountsetting isMounted & cleans up on unmount */
  useEffect(() => {
    isMounted.current = true
    return () => {
      isMounted.current = false
    }
  }, [])

  /** Handles tracking on init */
  useEffect(() => {
    if (partner && consumerCart?.id) {
      if (!isMounted.current) return

      const { id, orderItems, store } = consumerCart
      const { name: storeName } = store
      const { name: merchantName } = partner

      const eventData = {
        cart_id: id,
        products: orderItems.map((item) => ({
          product_id: item.variantId,
          name: item.name,
          currency: 'GBP',
          quantity: item.quantity,
          price: item.total.discounted || item.total.base
        }))
      }

      const ecommerceEventData = createEcommEventDataFromConsumerCart(
        consumerCart,
        merchantName,
        storeName
      )

      const body = {
        category: 'Cart',
        action: TrackableEvent.CartViewed
      }

      trackUserActionsGA4(body, 'slerpGA4Tracking')

      // legacy tracking
      trackUserActionsFBPixel(TrackableEvent.CartViewed, eventData)
      trackUserActionsGA4(body, 'merchantGA4Tracking')

      slerpGA4EcommTrackViewCart(ecommerceEventData)
      merchantGA4EcommTrackViewCart(ecommerceEventData)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [consumerCart?.id, partner])

  /** Checks if orderItems have been updated and sets shouldValidateCart to true */
  useEffect(() => {
    if (orderItems) {
      if (!isEqual(orderItemsRef.current, orderItems)) {
        setShouldValidateCart(true)
        orderItemsRef.current = orderItems
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderItems])

  /** Validates cart when shouldValidateCart is true */
  useEffect(() => {
    if (shouldValidateCart && consumerCart?.id) {
      setLoadingMessage('Updating cart')

      // Clear loading message if validate cart succeeds or fails
      validateCart(false).finally(() => {
        if (isMounted.current) {
          setLoadingMessage('')
        }
      })

      setShouldValidateCart(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [consumerCart?.id, shouldValidateCart])

  useEffect(() => {
    loadCartItems()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const loadCartItems = () => {
    if (!cartSession.cartId) {
      setAlcoholicProducts([])
      resetOrderItemStates()
      return
    }

    setLoadingMessage('Loading cart')

    // Keeps CartManager cart state up to date & triggers getConsumerCart
    loadCart()
      .then((cart: Cart | null) => {
        if (cart) {
          cartSession.setCart(cart)
        }
      })
      .catch(() => {
        // Only remove cart loading message if order items won't be updated
        setLoadingMessage('')
      })
  }

  /** Sets order items to render in UI from consumer product validations */
  useEffect(() => {
    if (!orderItems) return

    setAlcoholicProducts(getAlcoholicItems(orderItems))

    if (errors) {
      setLoadingMessage('Loading cart')
      const currentProductErrors = getCurrentProductErrors(errors)

      if (!currentProductErrors.length) {
        resetOrderItemStates()
        setLoadingMessage('')
        return
      }

      if (currentProductErrors.length) {
        let invalidOrderItems: (OrderItemV2 | ExtendedOrderItemV2)[] = []
        let unavailableOrderItems: (OrderItemV2 | ExtendedOrderItemV2)[] = []

        // Set invalid and unavailable order items based upon cart error messages
        currentProductErrors.forEach((productError) => {
          const items = getInvalidItems(productError, orderItems)
          if (
            productError.message === CartErrorMessage.PRODUCT_PRICES_CHANGED
          ) {
            // PRODUCT_PRICES_CHANGED
            invalidOrderItems.push(...items)
          } else {
            // INVALID_MODIFIERS, PRODUCT_UNAVAILABLE, PRODUCTS_OUT_OF_STOCK
            unavailableOrderItems.push(...items)
          }
        })

        setInvalidOrderItems(invalidOrderItems)
        setUnavailableOrderItems(unavailableOrderItems)

        const invalidAndUnavailableIds = [
          ...invalidOrderItems.map((item) => item.id),
          ...unavailableOrderItems.map((item) => item.id)
        ]

        const currentValidOrderItems = orderItems.filter(
          (orderItem: OrderItemV2) =>
            !invalidAndUnavailableIds.includes(orderItem.id)
        )

        setValidOrderItems(currentValidOrderItems)
        setLoadingMessage('')
      }
    } else {
      setLoadingMessage('Loading cart')
      resetOrderItemStates()
      setLoadingMessage('')
      return
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors, orderItems])

  /** Check if cartItems overlap with checkout button and set boolean to apply styling if so */
  useEffect(() => {
    const button = buttonRef.current
    const itemsContainer = itemsContainerRef.current

    if (
      itemsContainer &&
      button &&
      button.getBoundingClientRect().top <=
        itemsContainer.getBoundingClientRect().bottom
    ) {
      setBoxShadow(true)
    } else {
      setBoxShadow(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderItems])

  const resetOrderItemStates = () => {
    setInvalidOrderItems([])
    setUnavailableOrderItems([])
    setValidOrderItems(orderItems || [])
  }

  const EmptyCart = () => {
    if (
      loadingMessage === '' &&
      !orderItems?.length &&
      !invalidOrderItems.length &&
      !unavailableOrderItems.length
    ) {
      return (
        <EmptyCartContainer>
          <CartIcon size='24px' />
          <EmptyCartText>Your Cart is empty</EmptyCartText>
          <Button onClick={() => closeModal('cart')}>View Menu</Button>
        </EmptyCartContainer>
      )
    }

    return <></>
  }

  const isMinimumOrderValue = checkMinimumOrderValue(
    parseFloat(cartSubtotal ?? '0'),
    minimumOrderValue
  )

  const checkout = () => {
    if (!consumerCart?.id) return
    closeModal('cart')
    return history.push('/checkout')
  }

  const disableCheckout =
    !!unavailableOrderItems.length ||
    !!invalidOrderItems.length ||
    !isMinimumOrderValue

  return (
    <SideModal handleCloseModal={closeCartModal}>
      <Heading as='h2' fontWeight={600}>
        Your cart
      </Heading>
      {alcoholicProducts && !!alcoholicProducts.length && (
        <AlertMessage
          heading='You must be over 18 to purchase some products'
          subheading='You may be required to show your ID'
        />
      )}
      {!isMinimumOrderValue && (
        <AlertMessage
          heading={`You must spend a minimum of £${minimumOrderValue} to place your order`}
          subheading=''
          type='info'
        />
      )}
      <EmptyCart />
      <Items ref={itemsContainerRef}>
        {validOrderItems &&
          !!validOrderItems.length &&
          validOrderItems.map((orderItem: OrderItemV2, index: number) => (
            <CartItem
              key={orderItem.id}
              {...{ orderItem }}
              canEditQuantity={true}
              testLabel={'cart'}
              index={index}
            />
          ))}
      </Items>

      {invalidOrderItems && invalidOrderItems.length > 0 && (
        <AlertMessage
          heading='Pricing of these item(s) have changed.'
          subheading='Please remove and re-add these item(s) to your cart.'
          type='error'
        />
      )}

      <Items>
        {invalidOrderItems &&
          invalidOrderItems.length > 0 &&
          invalidOrderItems.map((orderItem: OrderItemV2, index: number) => (
            <CartItem
              key={orderItem.id}
              {...{ orderItem }}
              canEditQuantity={true}
              isInvalid={true}
              testLabel={'invalid'}
              index={index}
            />
          ))}
      </Items>

      {unavailableOrderItems && unavailableOrderItems.length > 0 && (
        <AlertMessage
          heading='These item(s) are not available in this store'
          subheading='Please remove these item(s) from your cart.'
          type='error'
        />
      )}

      <Items>
        {unavailableOrderItems &&
          !!unavailableOrderItems.length &&
          unavailableOrderItems.map((orderItem: OrderItemV2, index: number) => (
            <CartItem
              key={orderItem.id}
              {...{ orderItem }}
              canEditQuantity={true}
              index={index}
              testLabel={'unavailable'}
            />
          ))}
      </Items>

      {!!validOrderItems.length && (
        <Actions ref={buttonRef} buttonBoxShadowBool={boxShadow}>
          <Button
            testId='checkoutButton'
            onClick={checkout}
            disabled={disableCheckout}
          >
            Go to checkout・{formatMoney(cartSubtotal ?? '0')}
          </Button>
        </Actions>
      )}
      {loadingMessage !== '' && <SpinnerModal message={loadingMessage} />}
    </SideModal>
  )
}

const showBoxShadow = (showBoxShadowBool: boolean) => {
  if (showBoxShadowBool)
    return {
      WebkitBoxShadow: '0px -1px 0px rgb(50 50 50 / 5%)',
      MozBoxShadow: '0px -1px 0px rgb(50 50 50 / 5%)',
      boxShadow: '0px -1px 0px rgb(50 50 50 / 5%)',
      backgroundColor: 'white'
    }
  return {}
}

const Actions = styled.div<{ buttonBoxShadowBool: boolean }>(
  ({ buttonBoxShadowBool }) => ({
    marginTop: 'auto',
    textAlign: 'center',
    ...showBoxShadow(buttonBoxShadowBool),
    position: 'sticky',
    bottom: '0',
    padding: '32px 0'
  })
)

const Items = styled.div(() => ({
  flex: '0 0 auto',
  display: 'grid',
  gridGap: '20px',
  marginBottom: '16px'
}))

const EmptyCartText = styled.p(({ theme }: any) => ({
  color: theme.colors.black,
  fontSize: theme.fontSizes[2],
  fontWeight: 500,
  marginBottom: '60px'
}))

const EmptyCartContainer = styled.div(({ theme }: any) => ({
  margin: '35% 15% 0',
  textAlign: 'center',
  maxWidth: '350px',
  justifyContent: 'center',
  alignItems: 'center',
  display: 'flex',
  alignContent: 'center',
  flexDirection: 'column'
}))

const CartIcon = styled(BsCart2)(() => ({
  width: '80px',
  height: '80px'
}))

export default CartModal
