import { RouteComponentProps } from 'react-router-dom'
import {
  AppContentLoader,
  LoaderSwitcher,
  SlerpMerchantLoader
} from 'router/loaders'
import { Landing } from '../../shop/pages'

interface LandingProps extends RouteComponentProps {
  onClose: () => void
}

export const LandingWrapper = (props: LandingProps) => {
  const landingView = props.location.pathname.includes('view-all')
    ? 'list'
    : undefined

  return (
    <AppContentLoader>
      {(isParentLoaded) => (
        <SlerpMerchantLoader isParentLoaded={isParentLoaded}>
          {(isParentLoaded) => (
            <LoaderSwitcher isParentLoaded={isParentLoaded}>
              <Landing {...props} view={landingView} />
            </LoaderSwitcher>
          )}
        </SlerpMerchantLoader>
      )}
    </AppContentLoader>
  )
}
