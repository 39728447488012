import { useContext } from 'react'
import styled from '@emotion/styled'
import { AccountIcon } from 'shop/assets/icons'
import { useAppContent, useModal, useReactRouter } from 'shop/hooks'
import AccountMenu from './AccountMenu'
import CloseButton from '../Modal/CloseButton'
import { Button } from 'shop/components'
import { LandingContext } from './LandingContext'

const LandingHeaderBar = ({ onClose }: { onClose: () => void }) => {
  const { openModal, closeModal } = useModal()
  const { history } = useReactRouter()
  const { inModal } = useContext(LandingContext)
  const { altLogoImage } = useAppContent()
  const isLoggedIn = localStorage.getItem('customerId') !== null

  const handleLoginButtonClick = () => {
    if (isLoggedIn) {
      history.push('/account')
    } else {
      openModal('login')
    }
  }

  const handleCloseModal = () => {
    onClose()
    closeModal('fulfillment')
  }

  return (
    <LandingHeader id='nav-bar-header'>
      <LogoContainer>
        <Logo src={altLogoImage} />
      </LogoContainer>
      {inModal && (
        <ModalCloseButtonContainer>
          <CloseButton handleCloseModal={handleCloseModal} />
        </ModalCloseButtonContainer>
      )}
      {!inModal && (
        <AccountContainer>
          {isLoggedIn && <AccountMenu />}
          {!isLoggedIn && (
            <CustomerAccountSection>
              <CustomerAccountButton
                onClick={() => handleLoginButtonClick()}
                size='md'
                testId='accountsButton'
                width='auto'
              >
                <AccountIcon />
                Log in / Sign up
              </CustomerAccountButton>
            </CustomerAccountSection>
          )}
        </AccountContainer>
      )}
    </LandingHeader>
  )
}

const LandingHeader = styled.header(({ theme }: any) => ({
  borderBottom: `1px solid ${theme.colors['lineColor']}`,
  padding: theme.space[3],
  display: 'flex',
  position: 'sticky',
  top: 0,
  backgroundColor: theme.colors['white'],
  zIndex: theme.zIndex['stickyHeader']
}))

export const LogoContainer = styled.div(({ theme }: any) => ({
  display: 'flex',
  flexGrow: 1,
  WebkitTapHighlightColor: 'transparent'
}))

const Logo = styled.img(({ theme }: any) => ({
  maxWidth: '80%',
  maxHeight: '36px',
  [theme.mediaQueries.viewport7]: {
    maxHeight: '40px'
  }
}))

const ModalCloseButtonContainer = styled.div(({ theme }: any) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  '& > div': {
    position: 'unset'
  }
}))

const AccountContainer = styled.div(({ theme }: any) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  WebkitTapHighlightColor: 'transparent'
}))

const CustomerAccountButton = styled((props) => <Button {...props} />)(
  ({ theme }: any) => ({
    display: 'flex',
    alignItems: 'center',
    lineHeight: '16px',
    padding: '8px 16px',
    // Max width to force multi-line of CTA text
    maxWidth: '125px',

    // Viewport 4 is wide enough for a single line of CTA text
    [theme.mediaQueries.viewport4]: {
      maxWidth: 'unset'
    },

    // Bigger svg for multi-line
    '& svg': {
      height: '24px',
      width: '24px',

      [theme.mediaQueries.viewport4]: {
        height: '16px',
        width: '16px'
      }
    }
  })
)

const CustomerAccountSection = styled.div(({ theme }: any) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  [theme.mediaQueries.viewport7]: {
    justifyContent: 'flex-start',
    alignItems: 'unset'
  }
}))

export default LandingHeaderBar
