import { Dispatch, SetStateAction, useEffect, useMemo } from 'react'
import styled from '@emotion/styled'
import Theme, { StyledHTMLElement } from 'shop/theme/types'
import { PartnerStore } from '../types'
import { IoIosCloseCircleOutline, IoIosSearch } from 'react-icons/io'
import { useMediaQueries } from 'shop/hooks'
import { debounce } from 'lodash'

type Props = {
  allStores: PartnerStore[]
  setFilteredStores: Dispatch<SetStateAction<PartnerStore[]>>
  searchValue: string
  setSearchValue: Dispatch<SetStateAction<string>>
}

const StoreSearchInput = ({
  allStores,
  setFilteredStores,
  searchValue,
  setSearchValue
}: Props) => {
  const { isMobile } = useMediaQueries()

  const onSearch = (input: string) => {
    handleScroll()
    setSearchValue(input)

    if (input.length >= 2) {
      setFilteredStores(filterStores(input, allStores))
    } else {
      setFilteredStores(allStores)
    }
  }

  const handleScroll = () => {
    if (isMobile) {
      mobileScrollSearchToTop()
    }
  }

  const handleClear = () => {
    onSearch('')
    const searchInput = document.getElementById(
      'store-search-input'
    ) as HTMLInputElement
    if (searchInput) {
      searchInput.value = '' // clear user input
      if (isMobile) searchInput?.focus() // keep focus
    }
  }

  const filterStores = (input: string, stores: PartnerStore[]) => {
    const matchedStores = stores.filter((store) => {
      const storeName = store.name.toLowerCase()
      const inputToCompare = input.toLowerCase()
      const matchedName = storeName.includes(inputToCompare)
      if (matchedName) return matchedName
      const storePostcode = store.address?.zip?.toLowerCase() || ''
      const matchedPostcode = storePostcode.includes(inputToCompare)
      if (matchedPostcode) return matchedPostcode
      return false
    })
    return matchedStores
  }

  const debouncedOnSearch = useMemo(() => {
    return debounce((input: string) => onSearch(input), 300)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    return () => {
      debouncedOnSearch.cancel()
    }
  }, [])

  return (
    <InputContainer id='store-search' data-testid='store-search'>
      <SearchInput
        id='store-search-input'
        data-testid='store-search-input'
        placeholder='Search by Store name or Postcode'
        type='text'
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          debouncedOnSearch(e.target.value)
        }
        onKeyUp={handleScroll}
        onFocus={handleScroll}
        autoComplete='off'
      />
      <IconContainer>
        <IoIosSearch />
      </IconContainer>
      {!!searchValue && (
        <IconContainer position='right'>
          <IoIosCloseCircleOutline cursor={'pointer'} onClick={handleClear} />
        </IconContainer>
      )}
    </InputContainer>
  )
}

const mobileScrollSearchToTop = () => {
  const searchElement = document.getElementById('store-search')
  const headerElement = document.getElementById('nav-bar-header')

  if (!searchElement) {
    return
  }

  const navbarHeaderHeight = headerElement?.getBoundingClientRect().height || 50
  const gap = 16
  const scrollPositon =
    searchElement.getBoundingClientRect().top +
    window.pageYOffset -
    navbarHeaderHeight -
    gap

  window.scrollTo({ top: scrollPositon, behavior: 'smooth' })
}

const InputContainer = styled.div(() => ({
  position: 'relative',
  height: 'fit-content',
  width: '100%',

  svg: {
    fill: '#8B8B8B'
  },

  '&:focus-within': {
    svg: {
      fill: '#8B8B8B'
    }
  },

  // disable lastpass
  '& [data-lastpass-icon-root]': {
    display: 'none'
  }
}))

const SearchInput = styled.input<StyledHTMLElement, Required<Theme>>(
  ({ theme }) => ({
    width: '100%',
    height: '36px',
    fontSize: '16px',
    fontWeight: theme.fontWeights['light'],
    fontFamily: theme.fonts['body'].family,
    color: '#2A2A2A',
    outline: 0,
    border: `1px solid #D3D3D3`,
    borderRadius: `10px`,
    paddingLeft: '40px',
    paddingRight: '24px'
  })
)

const IconContainer = styled.div(
  ({ position = 'left' }: { position?: 'left' | 'right' }) => ({
    margin: 0,
    width: '20px',
    height: '20px',
    position: 'absolute',
    right: position === 'right' ? '12px' : 'auto',
    left: position === 'right' ? 'auto' : '12px',
    top: '50%',
    bottom: 0,
    transform: 'translateY(-50%)',

    '>svg': {
      width: '100%',
      height: '100%'
    }
  })
)

export default StoreSearchInput
